import common from './common';
import development from './development';
import production from './production';
import staging from './staging';

export default {
  common,
  development,
  production,
  staging,
};
