import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { FormattedMessage } from 'react-intl';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import { Divider, IconButton, Typography } from '../../../../ui-components';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  heading: {
    padding: theme.spacing(2),
  },
  settingsButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}));

function UploadHeader() {
  const {
    dispatch,
    settings: {
      values: { uploadName },
    },
  } = useGlobalStore();

  const classes = useStyles();

  const handleOpenSettings = () => {
    dispatch({ type: actions.SHOW_SETTINGS });
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h3"
        component="h2"
        className={classes.heading}
        testid="title"
      >
        <FormattedMessage
          id="upload.title"
          defaultMessage="Upload {uploadName}"
          values={{ uploadName }}
        />
      </Typography>
      <IconButton
        className={classes.settingsButton}
        aria-label={
          <FormattedMessage
            id="upload.settings"
            defaultMessage="Upload Settings"
          />
        }
        onClick={handleOpenSettings}
      >
        <SettingsIcon />
      </IconButton>
      <Divider />
    </div>
  );
}

export default UploadHeader;
