import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import BaseTable from '@material-ui/core/Table';
import BaseTableContainer from '@material-ui/core/TableContainer';
import BaseTableHead from '@material-ui/core/TableHead';
import BaseTableBody from '@material-ui/core/TableBody';
import BaseTableRow from '@material-ui/core/TableRow';
import BaseTableCell from '@material-ui/core/TableCell';

// Table
function Table({ children }) {
  return <BaseTable>{children}</BaseTable>;
}

Table.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

// Table Container
function TableContainer({ children, component }) {
  return (
    <BaseTableContainer component={component}>{children}</BaseTableContainer>
  );
}

TableContainer.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  component: PropTypes.string,
};

TableContainer.defaultProps = {
  component: 'div',
};

// Table Head
function TableHead({ children }) {
  return <BaseTableHead>{children}</BaseTableHead>;
}

TableHead.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

// Table Body
function TableBody({ children }) {
  return <BaseTableBody>{children}</BaseTableBody>;
}

TableBody.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

// Table Row
function TableRow({
  children,
  hover,
  role,
  tabIndex,
  selected,
  style,
  className,
  testid,
}) {
  return (
    <BaseTableRow
      hover={hover}
      role={role}
      tabIndex={tabIndex}
      selected={selected}
      style={style}
      className={className}
      data-testid={testid}
    >
      {children}
    </BaseTableRow>
  );
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  hover: PropTypes.bool,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  selected: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  testid: PropTypes.string,
};

TableRow.defaultProps = {
  hover: false,
  role: null,
  tabIndex: null,
  selected: false,
  style: null,
  className: null,
  testid: null,
};

// Table Cell
function TableCell({ children, align, colSpan, className, testid, onClick }) {
  return (
    <BaseTableCell
      align={align}
      colSpan={colSpan}
      className={className}
      data-testid={testid}
      onClick={onClick}
    >
      {children}
    </BaseTableCell>
  );
}

TableCell.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]),
  align: PropTypes.string,
  colSpan: PropTypes.number,
  className: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
};

TableCell.defaultProps = {
  children: null,
  align: 'inherit',
  colSpan: null,
  className: null,
  testid: null,
  onClick: null,
};

Table.Container = TableContainer;
Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
