import { useQuery } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';

export default function useUserQuery(userId, options) {
  const config = useConfig();

  return useQuery(
    ['user', userId],
    () => get(config.apiUrl, `/v1/users/${userId}`),
    { ...options, staleTime: Infinity },
  );
}
