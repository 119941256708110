import findKey from 'lodash/findKey';
import axios, { CancelToken } from 'axios';
import * as Sentry from '@sentry/browser';
import size from 'lodash/size';
import {
  CONNECTIONS_MAX,
  COMPRESSION_MAX,
  COMPRESSION_DEFAULT,
} from '../../../../utils/constants';
import FileCompressorWorkerPool from '../fileCompressor/fileCompressorWorkerPool';
import bucketClient from '../../../../utils/bucketClient';
import baseManifest from '../fileProcessing/baseManifest';
import {
  notifySlackUploadError,
  notifySlackUploadFileFailure,
  notifySlackUploadInfo,
} from '../../../../utils/notifySlack';
import { updatePartialUploadManifest } from '../../state/updatePartialUpload';

class Uploader {
  constructor(config) {
    this.bucket = config.bucket;
    this.fileQueue = config.files;
    this.manifest = config.manifest;
    this.padLength = config.files.length.toString().length;
    this.generatedManifest = {
      ...baseManifest(new Date()),
      cameras: config.manifest.cameras,
      files: {},
    };
    this.progress = config.progress ? new Set(config.progress) : null;
    this.workerPool = new FileCompressorWorkerPool();
    this.startTime = null;
    this.activeConnections = 0;
    this.isStartedTimer = null;

    // Settings
    this.uploadId = config.uploadId;
    this.compressionEnabled = config.compressionEnabled || false;
    this.compression = config.compression || COMPRESSION_DEFAULT;
    this.fineTuningEnabled = config.fineTuningEnabled || false;
    this.initialConnections = config.numberOfConnections || 1;
    this.maxConnections = CONNECTIONS_MAX;

    // Compression
    this.compressionSpeed = 0;

    // Upload
    this.authToken = null;
    this.totalBytesUploaded = 0;
    this.totalBytesUploadedSession = 0;
    this.oldAverageUploadSpeed = 0;
    this.averageUploadSpeed = 0;
    this.isPaused = false;
    this.cancelTokenSource = null;
    this.hasErrored = false;

    // Callbacks
    this.onUploadStarted = config.onUploadStarted;
    this.onFileUploaded = config.onFileUploaded;
    this.onFileCompressed = config.onFileCompressed;
    this.onUploadComplete = config.onUploadComplete;
    this.onUploadError = config.onUploadError;
    this.onCompressionChange = config.onCompressionChange;
    this.onOnline = config.onOnline;
    this.onOffline = config.onOffline;
    this.lastLogin = config.lastLogin;

    updatePartialUploadManifest(this.uploadId, config.manifest);

    this.addConnectionListeners();
  }

  updateCompressionSpeed(fileSize, duration) {
    this.compressionSpeed = fileSize / duration;
  }

  updateAverageUploadSpeed(fileSize, timeComplete) {
    this.totalBytesUploaded += fileSize;
    this.totalBytesUploadedSession += fileSize;
    this.oldAverageUploadSpeed = this.averageUploadSpeed;
    this.averageUploadSpeed = this.totalBytesUploadedSession / timeComplete;
  }

  addConnectionListeners() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.resume();

    if (typeof this.onOnline === 'function') {
      this.onOnline();
    }
  };

  handleOffline = () => {
    this.pause();

    if (typeof this.onOffline === 'function') {
      this.onOffline();
    }
  };

  handleError(error) {
    clearTimeout(this.isStartedTimer);
    this.hasErrored = true;
    this.workerPool.closeAll();

    if (typeof this.onUploadError === 'function') {
      this.onUploadError(error);
    }
  }

  handleComplete() {
    clearTimeout(this.isStartedTimer);

    this.workerPool.destroy();

    if (typeof this.onUploadComplete === 'function') {
      this.onUploadComplete({
        averageCompressionSpeed: this.compressionSpeed,
        averageUploadSpeed: this.averageUploadSpeed,
        bytesUploaded: this.totalBytesUploaded,
        duration: Date.now() - this.startTime,
      });
    }
  }

  handleCompressionChange(num) {
    this.compression += num;

    if (typeof this.onCompressionChange === 'function') {
      this.onCompressionChange(this.compression);
    }
  }

  async uploadFile(file, name, index, fileType, callOnFileUploaded = false) {
    try {
      await bucketClient({
        method: 'post',
        bucket: this.bucket,
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          'Content-Type': file.type,
        },
        params: {
          name: `${this.uploadId}/${name}`,
          uploadType: 'media',
        },
        data: file,
        cancelToken: this.cancelTokenSource.token,
      });

      const timeComplete = Date.now() - this.startTime;

      this.updateAverageUploadSpeed(file.size || file.length, timeComplete);

      if (typeof this.onFileUploaded === 'function' && callOnFileUploaded) {
        this.onFileUploaded({
          timeComplete,
          averageUploadSpeed: this.averageUploadSpeed,
        });
      }

      if (name !== 'manifest') {
        const fileKey = index.toString().padStart(this.padLength, 0);
        this.generatedManifest[fileKey] = this.manifest[fileKey];
      }
    } catch (error) {
      if (name !== 'manifest') {
        this.fileQueue.unshift(file);
      }

      if (axios.isCancel(error)) return;

      if (typeof this.onUploadError === 'function' && !this.hasErrored) {
        this.handleError(error);
      }

      notifySlackUploadFileFailure(this.uploadId, name);
      Sentry.withScope(scope => {
        scope.setTag('transaction', 'UPLOAD_FILE');
        scope.setTag('upload_size', file.size);
        scope.setTag('total_file_count', size(this.manifest.files));
        scope.setLevel(Sentry.Severity.Error);
        Sentry.captureException(error);
      });
    }

    // Reset to prevent memory leak with request object
    this.cancelTokenSource = null;
  }

  nextFile() {
    // eslint-disable-next-line no-async-promise-executor, consistent-return
    return new Promise(async resolve => {
      if (this.fileQueue.length === 0) {
        return resolve();
      }

      if (this.isPaused || this.hasErrored) return resolve();

      this.activeConnections += 1;

      const file = this.fileQueue.pop();
      const index = this.fileQueue.length;

      const uploadName = findKey(
        this.manifest.files,
        entry => entry.path === file.path,
      );

      if (
        this.progress &&
        this.progress.has(`${this.uploadId}/${uploadName}`)
      ) {
        await this.fineTune();
        return resolve();
      }

      const originalFileSize = file.size;

      if (this.compressionEnabled) {
        const worker = await this.workerPool.requestWorker();

        worker.postMessage({
          type: 'compress-and-upload',
          bucket: this.bucket,
          authToken: this.authToken,
          file,
          name: `${this.uploadId}/${uploadName}`,
          compression: this.compression,
        });

        worker.onmessage = async event => {
          const { type, duration, size: fileSize, error } = event.data;

          if (type === 'compression-complete') {
            const timeComplete = Date.now() - this.startTime;

            this.updateCompressionSpeed(
              originalFileSize,
              duration,
              timeComplete,
            );

            if (this.onFileCompressed) {
              this.onFileCompressed({
                timeComplete,
                compressionSpeed: this.compressionSpeed,
              });
            }

            return;
          }

          if (type === 'upload-complete') {
            const timeComplete = Date.now() - this.startTime;

            this.updateAverageUploadSpeed(fileSize, timeComplete);

            this.activeConnections -= 1;

            if (typeof this.onFileUploaded === 'function') {
              this.onFileUploaded({
                timeComplete,
                averageUploadSpeed: this.averageUploadSpeed,
              });
            }

            const fileKey = index.toString().padStart(this.padLength, 0);
            this.generatedManifest.files[fileKey] = this.manifest.files[
              fileKey
            ];
          }

          if (type === 'upload-cancelled') {
            this.fileQueue.unshift(file);
            return;
          }

          if (type === 'upload-error') {
            this.fileQueue.unshift(file);

            if (typeof this.onUploadError === 'function' && !this.hasErrored) {
              this.handleError(error);
            }

            notifySlackUploadFileFailure(this.uploadId, file.name, file.type);

            return;
          }

          this.workerPool.releaseWorker(worker);
          await this.fineTune();
          resolve();
        };
      } else {
        await this.uploadFile(file, uploadName, index, file.type, true);
        await this.fineTune();
        resolve();
      }
    });
  }

  // eslint-disable-next-line consistent-return
  fineTune() {
    if (this.fineTuningEnabled) {
      if (this.compressionSpeed > this.averageUploadSpeed) {
        if (this.compressionEnabled && this.compression < COMPRESSION_MAX) {
          this.handleCompressionChange(1);
        } else {
          this.compressionEnabled = true;
        }
      } else if (this.compressionSpeed < this.averageUploadSpeed) {
        if (this.compression > 1) {
          this.handleCompressionChange(-1);
        } else {
          this.compressionEnabled = false;
        }
      }

      if (
        this.averageUploadSpeed > this.oldAverageUploadSpeed &&
        this.activeConnections < this.maxConnections
      ) {
        return Promise.all([this.nextFile(), this.nextFile()]);
      }

      if (this.averageUploadSpeed - this.oldAverageUploadSpeed > -20) {
        return this.nextFile();
      }

      if (this.activeConnections === 0) {
        return this.nextFile();
      }
    } else {
      return this.nextFile();
    }
  }

  /**
   * Public Functions
   */

  async start(authToken) {
    this.startTime = Date.now();
    this.totalBytesUploadedSession = 0;

    this.authToken = authToken || this.authToken;

    this.cancelTokenSource = this.cancelTokenSource || CancelToken.source();

    const promises = [];

    for (let i = 0; i < this.initialConnections; i += 1) {
      const promise = this.nextFile();
      promises.push(promise);
    }

    if (!this.hasErrored) {
      this.isStartedTimer = setTimeout(() => {
        if (typeof this.onUploadStarted === 'function') {
          this.onUploadStarted();
        }
      }, 2000);
    }

    // Wait for all connections to close before completing the upload.
    await Promise.all(promises);

    if (this.isPaused || this.hasErrored) {
      return;
    }

    await this.uploadManifest();
    this.handleComplete();
  }

  async uploadManifest() {
    try {
      const manifestBlob = new Blob(
        [JSON.stringify(this.generatedManifest, null, 2)],
        {
          type: 'application/json',
        },
      );

      await this.uploadFile(
        manifestBlob,
        `manifest`,
        this.generatedManifest.files.length,
        'application/json',
        true,
      );
      notifySlackUploadInfo(this.uploadId, 'Manifest Uploaded');
    } catch (error) {
      notifySlackUploadError(
        this.uploadId,
        'Could not build and upload manifest',
      );
      Sentry.captureException(
        new Error(
          `Could not build and upload manifest.  Last login: ${this.lastLogin}`,
        ),
      );
    }
  }

  pause() {
    this.isPaused = true;

    if (this.compressionEnabled) {
      this.workerPool.closeAll();
    } else {
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = null;
    }
  }

  resume(authToken) {
    this.isPaused = false;
    this.hasErrored = false;
    this.start(authToken);
  }
}

export default Uploader;
