import isString from 'lodash/isString';
import bucketClient from '../../../utils/bucketClient';

async function getObjects(bucket, authToken, id, pageToken) {
  if (!isString(bucket) || !isString(authToken) || !isString(id)) {
    throw new Error('bucket, authToken and id should be strings');
  }

  try {
    const response = await bucketClient({
      method: 'get',
      bucket,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        prefix: id,
        pageToken,
      },
    });

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// A list operation on a gcs bucket returns a max of 1000 items
// Recursively call using the nextPageToken to get a full list
function getAllObjects(bucket, authToken, id) {
  const doGetObjects = (nextPageToken, acc) => {
    return getObjects(bucket, authToken, id, nextPageToken).then(data => {
      if (!data.nextPageToken) {
        return acc.concat(data.items);
      }
      return doGetObjects(data.nextPageToken, acc.concat(data.items));
    });
  };

  return doGetObjects(null, []);
}

export default async (bucket, authToken, id) => {
  const objects = await getAllObjects(bucket, authToken, id);

  return objects.reduce((acc, item) => acc.concat(item.name), []);
};
