import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';

const getFieldStyle = (field, activeField) => {
  const style = {
    color: '#fff',
    weight: 2,
  };

  if (field.id === activeField) {
    style.color = '#ffeb3b';
    style.weight = 3;
  }

  return style;
};

const MapFieldsLayer = () => {
  const {
    map: { activeField, matchingBaseZones = [] },
  } = useGlobalStore();

  return (
    <>
      {matchingBaseZones.map(baseZone => {
        const { field = {}, geometry = {} } = baseZone;
        return (
          <GeoJSON
            key={field.id}
            data={geometry}
            style={() => getFieldStyle(field, activeField)}
          />
        );
      })}
    </>
  );
};

export default MapFieldsLayer;
