import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';

const MapFieldsFlownAreaLayer = () => {
  const {
    upload: { coverageData = {} },
  } = useGlobalStore();
  return (
    <>
      {Object.keys(coverageData).map(id => {
        const { geometry } = coverageData[id];

        return geometry ? (
          <GeoJSON key={`flown-area-${id}`} data={geometry} />
        ) : null;
      })}
    </>
  );
};

export default MapFieldsFlownAreaLayer;
