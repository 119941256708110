import React from 'react';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import Notification from './Notification';

function Notifications() {
  const {
    upload: { errors },
  } = useGlobalStore();

  if (!errors.byType) {
    return null;
  }

  return (
    errors.byType &&
    Object.keys(errors.byType).map(type => {
      return Object.keys(errors.byType[type]).map(key => {
        const { count, files } = errors.byType[type][key];
        return (
          <Notification
            key={key}
            errorType={type}
            errorKey={key}
            count={count}
            files={files}
          />
        );
      });
    })
  );
}

export default Notifications;
