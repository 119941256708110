import React from 'react';
import PropTypes, { oneOfType, string, func, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BaseInput from '@material-ui/core/TextField';
import ConditionalWrap from './ConditionalWrap';
import FormControl from './FormControl';

const useStyles = makeStyles(theme => ({
  control: {
    margin: theme.spacing(2, 0, 0),
  },
}));

function Input({
  name,
  label,
  type,
  $value,
  value,
  inputProps,
  helperText,
  isWrapped,
  fullWidth,
  className,
  disabled,
  useLocalStorage,
  onChange,
  onBlur,
}) {
  const classes = useStyles();

  const handleOnChange = e => {
    if ($value) {
      $value.set(e.target.value);
    }

    if (useLocalStorage) {
      localStorage.setItem(e.target.name, e.target.value);
    }

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const val = $value ? $value.value : value;
  const error = $value ? $value.error : null;

  return (
    <ConditionalWrap
      condition={isWrapped}
      wrap={child => (
        <FormControl fullWidth={fullWidth} className={classes.control}>
          {child}
        </FormControl>
      )}
    >
      <BaseInput
        name={name}
        type={type}
        label={label}
        disabled={disabled}
        helperText={helperText}
        className={className}
        value={val}
        inputProps={{ ...inputProps, 'data-testid': `input-${name}` }}
        onChange={handleOnChange}
        onBlur={onBlur}
      />
      {error && <span>{error}</span>}
    </ConditionalWrap>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  type: PropTypes.string,
  $value: PropTypes.shape({
    value: oneOfType([string, number]),
    set: func,
    check: func,
    error: string,
  }),
  value: oneOfType([string, number]),
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  helperText: PropTypes.node,
  disabled: PropTypes.bool,
  isWrapped: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  useLocalStorage: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  label: '',
  type: 'text',
  $value: null,
  value: '',
  inputProps: {},
  helperText: '',
  disabled: false,
  isWrapped: false,
  fullWidth: false,
  className: null,
  useLocalStorage: false,
  onChange: null,
  onBlur: null,
};

export default Input;
