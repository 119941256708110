import get from 'lodash/get';
import isString from 'lodash/isString';
import getPartialUploadById from './getPartialUploadById';
import getProgress from './getProgress';
import manifestToPoints from '../services/fileProcessing/manifestToPoints';

export default async (bucket, uploadToken, id) => {
  let partialUpload;
  let progress;

  if (!isString(bucket) || !isString(uploadToken) || !isString(id)) {
    throw new Error('bucket, uploadToken and id should be strings');
  }

  try {
    partialUpload = await getPartialUploadById(id);
    partialUpload.token = uploadToken;
  } catch (error) {
    console.warn(
      'Could not retrieve stored partial upload info',
      error.toString(),
    );
  }

  try {
    progress = await getProgress(bucket, uploadToken, id);
  } catch (error) {
    return Promise.reject(error);
  }

  const manifest = get(partialUpload, 'manifest', {});
  const points = manifestToPoints(manifest);

  return { partialUpload, manifest, progress, points };
};
