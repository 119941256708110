import { getUnixTime } from 'date-fns';

/** Sorts an array of objects with a date property */
export const sortByDate = (data, properties, sortDirection = 'asc') => {
  const isAsc = sortDirection === 'asc';

  return data.sort((a, b) => {
    const dateA = getUnixTime(new Date(a[properties]));
    const dateB = getUnixTime(new Date(b[properties]));

    return isAsc ? dateA - dateB : dateB - dateA;
  });
};
