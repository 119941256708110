import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { useGlobalStore } from '../../../App/redux/GlobalStore';

function SpeedChart() {
  const {
    upload: { uploadData, compressionData },
  } = useGlobalStore();

  return (
    <>
      <Scatter
        responsive
        height={250}
        data={{
          datasets: [
            {
              label: 'Average upload speed',
              data: uploadData,
              backgroundColor: 'rgb(78, 201, 105, 0.5)',
              pointBackgroundColor: '#4EC969',
              pointBorderColor: '#40A556',
              pointBorderWidth: 0,
              pointRadius: 2,
              showLine: true,
              lineTension: 0,
              spanGaps: true,
              fill: true,
            },
            {
              label: 'Compression speed',
              data: compressionData,
              backgroundColor: 'rgb(201, 70, 70, 0.5)',
              pointBackgroundColor: '#CD5656',
              pointBorderColor: '#C94646',
              pointBorderWidth: 0,
              pointRadius: 2,
              showLine: true,
              lineTension: 0,
              spanGaps: true,
              fill: true,
            },
          ],
        }}
        options={{
          title: {
            display: true,
            text: 'Compression speed vs Average upload speed',
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 1000,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Speed (Kbps)',
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 60,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Total upload time (seconds)',
                },
              },
            ],
          },
        }}
      />
    </>
  );
}

export default SpeedChart;
