import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '../../../ui-components';
import HelpStep from './HelpStep';

function HelpContentNew() {
  const intl = useIntl();

  return (
    <>
      <HelpStep
        step="1"
        title={intl.formatMessage({
          id: 'helpContent.createANewUpload',
          defaultMessage: 'Create a new upload',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.toStartANewUploadClick"
            defaultMessage="To start a new upload, click the 'CREATE NEW UPLOAD' button on the Home screen."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="2"
        title={intl.formatMessage({
          id: 'helpContent.selectTheFiles',
          defaultMessage: 'Select the files',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.dragAndDropOrClickInTheUpload"
            defaultMessage="Drag & drop or click 'BROWSE FILES' in the 'Upload' box to select the folder containing the files to be uploaded. When selected, the browser will open a dialog: 'Upload x files to this site?'. Click the 'Upload' button in this dialog to continue."
          />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.ifTheFolderContainsALargeNumberOf"
            defaultMessage="If the folder contains a large number of files, there may be a delay before this dialog appears and also a delay after you click the 'Upload' button to continue, so please be patient."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="3"
        title={intl.formatMessage({
          id: 'helpContent.fileProcessing',
          defaultMessage: 'File processing',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.afterSelectingTheFiles"
            defaultMessage="After selecting the files, the app will process each file to check whether the required metadata, including geolocation and sensor information, is present."
          />
        </Typography>
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.thisProcessCanTakeSomeTime"
            defaultMessage="This process can take some time and progress will be displayed in the 'Upload' box. Please wait until processing has finished. An upload cannot be started until processing is complete."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="4"
        title={intl.formatMessage({
          id: 'helpContent.fileProcessingErrors',
          defaultMessage: 'File processing errors',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.ifThereAreProblemsFound"
            defaultMessage="If there are problems found during this process, details will appear in an 'Errors' tab in the 'Upload' box. Please check each error and fix any issues identified before repeating these steps."
          />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.uploadingWillBeDisabledIfThereAreErrors"
            defaultMessage="Uploading will be disabled if there are any errors. If you are confident that the only remaining errors can be disregarded for the specific files identified, e.g. missing metadata for images used for calibration. You can override this restriction by clicking the checkbox."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="5"
        title={intl.formatMessage({
          id: 'helpContent.flightPathsAndFieldCoverage',
          defaultMessage: 'Flight paths and field coverage',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.whenTheFilesHaveBeenProcessed"
            defaultMessage="When the files have been processed, the flight paths for each sensor and the coverage for each fields will be displayed."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="6"
        title={intl.formatMessage({
          id: 'helpContent.uploadName',
          defaultMessage: 'Upload name',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.theUploadNameWillBeSetAutomatically"
            defaultMessage="The upload name will be set automatically as the name of the folder you have selected. You can change this if you wish, click {icon} to open settings and enter a preferred name in the input field provided. Changes will be saved automatically."
            values={{ icon: <SettingsIcon fontSize="small" /> }}
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="7"
        title={intl.formatMessage({
          id: 'helpContent.startTheUpload',
          defaultMessage: 'Start the upload',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.clickTheUploadFilesButtonToBegin"
            defaultMessage="Click the 'UPLOAD FILES' button to begin the upload. Progress will shown in the 'Upload' box. Please do not navigate away from this page while the upload is in progress."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="8"
        title={intl.formatMessage({
          id: 'helpContent.pauseAndResume',
          defaultMessage: 'Pause and resume',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.youMustKeepTheUploadPageOpen"
            defaultMessage="You must keep the upload page open for the upload to progress. However, you can pause and resume the upload at another time if you wish. See the next tab for a guide to incomplete uploads."
          />
        </Typography>
      </HelpStep>
    </>
  );
}
export default HelpContentNew;
