import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

export default function useFlightsListQuery(startDate, endDate, options) {
  const { apiUrl } = useConfig();

  return useQuery(
    ['flights-list', startDate, endDate],
    () =>
      get(apiUrl, '/v1/flights', {
        start_date: startDate,
        end_date: endDate,
      }).then(result => result?.flights),
    options,
  );
}
