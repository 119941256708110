import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import BaseCard from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    padding: ({ flush }) => (flush ? 0 : theme.spacing(4)),
  },
}));

function Card({ children, raised, flush, className }) {
  const classes = useStyles({ flush });

  return (
    <BaseCard className={cx(classes.root, className)} raised={raised}>
      {children}
    </BaseCard>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  raised: PropTypes.bool,
  flush: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  raised: false,
  flush: false,
  className: null,
};

export default Card;
