import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Loader, Table } from '../../../../ui-components';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 72,
  },
  wrap: {
    marginTop: theme.spacing(4),
  },
  actions: {
    textAlign: 'right',
  },
}));

const { Container, Head, Body, Row, Cell } = Table;

function UploadsTable({ columns, children, isFetching }) {
  const classes = useStyles();

  if (isFetching) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <Table>
        <Head>
          <Row>
            {columns.map(column => (
              <Cell
                key={column.key}
                className={cx({ [classes.actions]: column.key === 'actions' })}
              >
                {column.name}
              </Cell>
            ))}
          </Row>
        </Head>
        <Body>{children}</Body>
      </Table>
    </Container>
  );
}

UploadsTable.propTypes = {
  /** Data table rows */
  children: PropTypes.node.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool,
};

UploadsTable.defaultProps = {
  isFetching: false,
};

export default UploadsTable;
