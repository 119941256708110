import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonsSpacer } from '../../../../ui-components';
import UploadsPartial from './UploadsPartial';
import UploadsComplete from './UploadsComplete';
import UploadsAll from './UploadsAll';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrap: {
    marginTop: theme.spacing(4),
  },
}));

function Uploads() {
  const classes = useStyles();

  return (
    <>
      <div>
        <ButtonsSpacer variant="right">
          <Button to="/upload/new" color="primary" testid="button-new-upload">
            <FormattedMessage
              id="uploads.createNewUpload"
              defaultMessage="Create new upload"
            />
          </Button>
        </ButtonsSpacer>
      </div>

      <div className={classes.wrap}>
        <UploadsPartial />
      </div>

      <div className={classes.wrap}>
        <UploadsComplete />
      </div>

      <div className={classes.wrap}>
        <UploadsAll />
      </div>
    </>
  );
}

export default Uploads;
