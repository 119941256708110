import {
  shape,
  number,
  string,
  bool,
  oneOfType,
  func,
  instanceOf,
} from 'prop-types';

export const useLinkType = shape({
  value: oneOfType([string, number, bool, instanceOf(Date)]),
  set: func,
});

export default null;
