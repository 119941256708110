import { gql, useQuery } from '@apollo/client';

const GET_FIELD = gql`
  query Report_Field($fieldId: ID!) {
    field(id: $fieldId) {
      id
      name
      farm {
        id
        name
      }
    }
  }
`;

function useField(fieldId) {
  return useQuery(GET_FIELD, {
    variables: { fieldId },
    skip: !fieldId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
}

export default useField;
