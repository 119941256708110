import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '../../../ui-components';

const useStyles = makeStyles(theme => ({
  wrap: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  step: {
    display: 'inline-block',
    width: 26,
    height: 26,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: '#fff',
    fontWeight: 500,
    paddingTop: 2,
    marginRight: theme.spacing(1),
  },
  title: {
    '& span': {
      marginLeft: theme.spacing(-4),
    },
  },
}));

function HelpStep({ step, title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <Typography
        variant="h4"
        component="h3"
        className={classes.title}
        gutterBottom
      >
        <span className={classes.step}>{step}</span>
        {title}
      </Typography>

      {children}
    </div>
  );
}

HelpStep.propTypes = {
  step: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

HelpStep.defaultProps = {
  title: null,
};

export default HelpStep;
