import { LineUtil } from 'leaflet';

function extractPoints(points) {
  return Object.keys(points).reduce((acc, path) => {
    const simplified = LineUtil.simplify(
      points[path].map(po => po.point),
      1e-12,
      true,
    ).map(po => [po.y, po.x]);
    return acc.concat(simplified);
  }, []);
}

export default extractPoints;
