import React from 'react';
import { Box, Card, Grid } from '../../../ui-components';
import Layout from '../../../App/Layout';
import Upload from '../components/Upload/Upload';
import MapContainer from '../components/Map/MapContainer';
import SpeedChart from '../components/SpeedChart';
import UploadStats from '../components/UploadStats';

function UploadTesterScene() {
  return (
    <Layout maxWidth="lg" container>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Box spacing={{ my: 4 }}>
            <Card>
              <Upload />
            </Card>
          </Box>
          <Box spacing={{ my: 4 }}>
            <Card>
              <UploadStats />
            </Card>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Box spacing={{ my: 4 }}>
            <Card flush>
              <MapContainer />
            </Card>
          </Box>
          <Box spacing={{ my: 4 }}>
            <Card flush>
              <SpeedChart />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default UploadTesterScene;
