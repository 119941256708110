import React from 'react';
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  AuthProvider,
  LogoutRedirect,
  useAuth,
} from '@hummingbirdtechgroup/wings-auth';
import { ConfigProvider, useConfig } from '@hummingbirdtechgroup/wings-config';
import { GlobalProvider } from './redux/GlobalStore';
import theme from './theme';
import RouteChange from './RouteChange';
import Flash from '../modules/Upload/components/Flash/FlashContainer';
import Routes from './Routes';
import SentryScope from './SentryScope';
import LocaleProvider from './LocaleProvider';
import queryClient from './queryClient';
import '../assets/styles/leaflet.css';
import apolloConfig from '../gqlClient/client';

// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

function App() {
  const { apiUrl } = useConfig();
  const apolloCache = new ApolloClient(apolloConfig(apiUrl));

  // Temporary redirect solution, remove in a couple of days
  const { user } = useAuth();
  if (user?.id) return <LogoutRedirect />;

  return (
    <ApolloProvider client={apolloCache}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <GlobalProvider>
          <LocaleProvider>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                  <RouteChange>
                    <Routes />
                    <Flash />
                  </RouteChange>
                </BrowserRouter>
                <SentryScope />
              </ThemeProvider>
            </AuthProvider>
          </LocaleProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

function AppContainer() {
  const apiEnv = process.env.API_ENV;
  return (
    <ConfigProvider env={apiEnv}>
      <App />
    </ConfigProvider>
  );
}

export default AppContainer;
