import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function AccordionSummary({ children }) {
  return (
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {children}
    </ExpansionPanelSummary>
  );
}

AccordionSummary.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

function AccordionDetails({ children }) {
  return <ExpansionPanelDetails>{children}</ExpansionPanelDetails>;
}

AccordionDetails.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

function AccordionPanel({ children }) {
  return <ExpansionPanel square>{children}</ExpansionPanel>;
}

AccordionPanel.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
};

function Accordion() {}

Accordion.Panel = AccordionPanel;
Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

export default Accordion;
