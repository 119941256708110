import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from 'lodash';
import { Box, Loader, Table } from '../../../ui-components';
import { useSurveyQuery } from '../../../services/survey';
import useField from '../../../services/field/useField';

const NO_DATA = '-';
const YES = 'Yes';
const NO = 'No';
const UN_AVAILABLE = 'Un-available';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const { Row, Cell } = Table;

const reportBtn = (surveyId, orthoId) =>
  surveyId &&
  orthoId && (
    <a
      rel="noreferrer"
      target="_blank"
      href={`https://storage.cloud.google.com/hummingbirdtech-production-processed/${surveyId}/${orthoId}/report.pdf`}
    >
      Link
    </a>
  );

function UploadSurveysRow({ surveyId }) {
  const classes = useStyles();
  const config = useConfig();

  const { isLoading, data: survey } = useSurveyQuery(surveyId);
  const preProcessedImages = get(survey, 'preprocessed_images');
  const { data: fieldData } = useField(survey?.field_id);

  const fieldName = get(fieldData, 'field.name');
  const farmName = get(fieldData, 'field.farm.name');

  return isLoading || !survey ? (
    <Row>
      <Cell colSpan="8" align="center">
        <Box className={classes.loader}>
          <Loader />
        </Box>
      </Cell>
    </Row>
  ) : (
    <Row>
      <Cell>
        <a
          rel="noreferrer"
          target="_blank"
          href={`${config.adminUrl}surveys/${survey.id}`}
        >
          {survey.id}
        </a>
      </Cell>
      <Cell>{farmName ?? NO_DATA}</Cell>
      <Cell>{fieldName ?? NO_DATA}</Cell>
      <Cell>
        {preProcessedImages?.map(imgs => <p>{imgs.image_type}</p>) ?? NO_DATA}
      </Cell>
      <Cell>
        {preProcessedImages?.map(imgs => <p>{imgs.total}</p>) ?? NO_DATA}
      </Cell>
      <Cell align="center">
        {/* // todo:<migration> Use the signed_url supplied in the reponse  - SFR 2021-05-20 */}
        {preProcessedImages?.map(imgs => (
          <p>{reportBtn(surveyId, imgs.id) ?? UN_AVAILABLE}</p>
        ))}
      </Cell>
      <Cell align="center">{survey.enabled ? YES : NO}</Cell>
    </Row>
  );
}

UploadSurveysRow.propTypes = {
  surveyId: PropTypes.string.isRequired,
};

export default UploadSurveysRow;
