import React from 'react';
import { oneOfType, arrayOf, node, bool, func } from 'prop-types';

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : <>{children}</>;
};

ConditionalWrap.propTypes = {
  condition: bool.isRequired,
  wrap: func.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default ConditionalWrap;
