import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import Layout from '../../../App/Layout';
import { Box, Card, Grid, Loader, Typography } from '../../../ui-components';
import { useFlightQuery } from '../../../services/survey';
import { useUserQuery } from '../../../services/user';
import UploadMetadata from '../components/UploadMetadata';
import UploadSurveysList from '../components/UploadSurveysList';
import MapFlightPath from '../components/MapFlightPath';
import uuidRegexExp from '../../../constants/uuidRegexExp';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrap: {
    marginTop: theme.spacing(4),
  },
}));

function ReportScene() {
  const classes = useStyles();
  const { flightId } = useParams();

  const { data: flight, isLoading } = useFlightQuery(flightId);
  const uploaderId = get(flight, 'created_by');
  const surveyIdList = get(flight, 'survey_ids', []);
  const flightPath = get(flight, 'flight_path');

  const { data: user } = useUserQuery(uploaderId, {
    enabled: Boolean(uploaderId) && uuidRegexExp.test(uploaderId),
  });
  const uploaderEmail = get(user, 'email');

  return (
    <Layout maxWidth="lg" container>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box spacing={{ my: 4 }}>
            <Card>
              <Typography variant="h3" component="h2">
                <FormattedMessage
                  id="upload.uploadMetadata"
                  defaultMessage="Upload Metadata"
                />
              </Typography>
              {isLoading ? (
                <div className={classes.loader}>
                  <Loader />
                </div>
              ) : (
                <UploadMetadata flight={flight} uploaderEmail={uploaderEmail} />
              )}

              <Typography variant="h3" component="h2">
                <FormattedMessage
                  id="upload.uploadSurveys"
                  defaultMessage="Upload Surveys"
                />
              </Typography>
              {isLoading ? (
                <div className={classes.loader}>
                  <Loader />
                </div>
              ) : (
                <UploadSurveysList surveyIdList={surveyIdList} />
              )}

              <Typography variant="h3" component="h2">
                <FormattedMessage
                  id="upload.flightPath"
                  defaultMessage="Flight Path"
                />
              </Typography>
              {isLoading ? (
                <div className={classes.loader}>
                  <Loader />
                </div>
              ) : (
                <MapFlightPath flightPath={flightPath} />
              )}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ReportScene;
