import get from 'lodash/get';
import * as actions from './actions';

const initialMapState = {
  isMapLoading: false,
  points: null,
  fields: null,
  matchingBaseZones: [],
  activeField: null,
};

const mapReducer = (state, action) => {
  switch (action.type) {
    case actions.FETCHING_UPLOAD_SUCCESS:
      return {
        ...state,
        points: get(action, 'payload.points', null),
      };

    case actions.FILES_PROCESSED:
      return {
        ...state,
        points: action.payload.points,
      };

    case actions.SET_ACTIVE_FIELD:
      return {
        ...state,
        activeField: action.payload,
      };

    case actions.SET_MATCHING_BASE_ZONES:
      return {
        ...state,
        matchingBaseZones: action.payload,
      };

    case actions.LOCATION_CHANGE:
    case actions.CLEAR:
      return initialMapState;

    default:
      return state;
  }
};

export { initialMapState, mapReducer };
