import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import BaseFormControl from '@material-ui/core/FormControl';

function FormControl({
  children,
  className,
  color,
  component,
  disabled,
  error,
  fullWidth,
  hiddenLabel,
  margin,
  required,
}) {
  return (
    <BaseFormControl
      className={className}
      color={color}
      component={component}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      margin={margin}
      required={required}
    >
      {children}
    </BaseFormControl>
  );
}

FormControl.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  margin: PropTypes.string,
  required: PropTypes.bool,
};

FormControl.defaultProps = {
  className: null,
  color: 'primary',
  component: 'div',
  disabled: false,
  error: false,
  fullWidth: false,
  hiddenLabel: false,
  margin: 'none',
  required: false,
};

export default FormControl;
