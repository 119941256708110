import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import UploadProgress from './UploadProgress';

function UploadProgressContainer({ isOnline, compression }) {
  const {
    upload: { uploadSpeed, filesUploadedCount, totalFiles },
    progress: { isStarting, isStarted, isInProgress, isPaused, isPostUpload },
  } = useGlobalStore();

  const percentageComplete =
    totalFiles > 0 && filesUploadedCount > 0
      ? (filesUploadedCount / totalFiles) * 100
      : 0;

  if (isStarting || isInProgress || isPostUpload || filesUploadedCount > 0) {
    return (
      <UploadProgress
        totalFiles={totalFiles}
        percentageComplete={percentageComplete}
        isStarting={isStarting}
        isStarted={isStarted}
        isPostUpload={isPostUpload}
        isPaused={isPaused}
        isOnline={isOnline}
        filesUploadedCount={filesUploadedCount}
        uploadSpeed={uploadSpeed}
        compression={compression}
      />
    );
  }

  return null;
}

UploadProgressContainer.propTypes = {
  isOnline: PropTypes.bool,
  compression: PropTypes.number,
};

UploadProgressContainer.defaultProps = {
  isOnline: true,
  compression: 3,
};

export default UploadProgressContainer;
