import Worker from './fileCompressor.worker';

class FileCompressorWorkerPool {
  constructor() {
    this.maxWorkers = navigator.hardwareConcurrency;
    this.createdWorkers = [];
    this.availableWorkers = [];
    this.queue = [];
  }

  getWorkers() {
    return this.createdWorkers;
  }

  createWorker() {
    const worker = new Worker();
    this.createdWorkers.push(worker);
    return worker;
  }

  requestWorker() {
    return new Promise(resolve => {
      if (this.availableWorkers.length > 0) {
        const worker = this.availableWorkers.pop();
        resolve(worker);
      } else if (this.createdWorkers.length < this.maxWorkers) {
        const worker = this.createWorker();
        resolve(worker);
      } else this.queue.push(resolve);
    });
  }

  releaseWorker(worker) {
    if (this.queue.length > 0) this.queue.shift()(worker);
    // Resolve a queued promise.
    else this.availableWorkers.push(worker);
  }

  closeAll() {
    this.createdWorkers.forEach(worker => {
      worker.postMessage({
        type: 'cancel',
      });
    });

    this.availableWorkers = [];
    this.createdWorkers = [];
    this.queue = [];
  }

  destroy() {
    this.createdWorkers.forEach(worker => {
      worker.terminate();
    });
  }
}

export default FileCompressorWorkerPool;
