import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import getPartialUploads from '../../state/getPartialUploads';
import deletePartialUpload from '../../state/deletePartialUpload';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import { Button, Dialog, Typography, Table } from '../../../../ui-components';
import UploadTable from './UploadsTable';
import UploadsTableRow from './UploadsTableRow';

const { Row, Cell } = Table;

const { DialogContentText, DialogActions } = Dialog;

const partialUploadsColumns = [
  {
    key: 'startDate',
    name: (
      <FormattedMessage id="uploads.startDate" defaultMessage="Start date" />
    ),
  },
  {
    key: 'uploadName',
    name: (
      <FormattedMessage id="uploads.uploadName" defaultMessage="Upload name" />
    ),
  },
  {
    key: 'filesUploadedCount',
    name: (
      <FormattedMessage
        id="uploads.filesUploaded"
        defaultMessage="Files uploaded"
      />
    ),
  },
  {
    key: 'totalFiles',
    name: (
      <FormattedMessage id="uploads.totalFiles" defaultMessage="Total files" />
    ),
  },
  {
    key: 'actions',
    name: <FormattedMessage id="uploads.actions" defaultMessage="Actions" />,
  },
];

function UploadsPartial() {
  const dispatch = useGlobalStore();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      try {
        const response = await getPartialUploads();
        setIsFetching(false);
        setUploads(response.filter(item => !item.complete));
      } catch (error) {
        setIsFetching(false);
        actions.showError(error, 'Error fetching partial uploads');
      }
    }
    fetchData();
  }, []);

  const handleRowClick = item => {
    history.push(`/upload/${item.id}`);
  };

  const handleDeleteClick = item => {
    setItemToDelete(item);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePartialUpload(itemToDelete.id);
      setUploads(items => items.filter(item => item.id !== itemToDelete.id));
      setItemToDelete(null);
    } catch (error) {
      dispatch(actions.showError(error, 'Error deleting item'));
    }
  };

  const handleClose = () => {
    setItemToDelete(null);
  };

  return (
    <>
      <Typography variant="h3" component="h2">
        <FormattedMessage
          id="uploads.uploadsInProgress"
          defaultMessage="Uploads in progress"
        />
      </Typography>

      <UploadTable columns={partialUploadsColumns} isFetching={isFetching}>
        {uploads.length > 0 ? (
          uploads.map(item => (
            <UploadsTableRow
              key={item.id}
              item={item}
              columns={partialUploadsColumns}
              handleRowClick={handleRowClick}
              handleDeleteClick={handleDeleteClick}
            />
          ))
        ) : (
          <Row>
            <Cell colSpan={partialUploadsColumns.length} align="center">
              <FormattedMessage
                id="uploads.noRecentlyCompletedUploads"
                defaultMessage="No recently completed uploads"
              />
            </Cell>
          </Row>
        )}
      </UploadTable>

      <Dialog
        title={
          <FormattedMessage
            id="uploads.youreAboutToDeleteThisUpload"
            defaultMessage="You're about to delete this upload"
          />
        }
        open={!!itemToDelete}
        onClose={handleClose}
      >
        <DialogContentText>
          <FormattedMessage
            id="uploads.doYouWishToContinue"
            defaultMessage="Do you wish to continue"
          />
        </DialogContentText>
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleClose}>
            <FormattedMessage id="uploads.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            autoFocus
            variant="text"
            color="primary"
            onClick={handleDeleteConfirm}
          >
            <FormattedMessage id="uploads.confirm" defaultMessage="Confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadsPartial;
