export default (files, manifest) => {
  const padLength = files.length.toString().length;
  const errorMessage =
    'The selected files do not match this incomplete upload. Please select the correct files or create a new upload.';

  return new Promise((resolve, reject) => {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      const fileKey = i.toString().padStart(padLength, 0);

      if (!manifest.files[fileKey]) {
        reject(new Error(errorMessage));
      }

      if (file.path !== manifest.files[fileKey].path) {
        reject(new Error(errorMessage));
      }

      resolve();
    }
  });
};
