import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import { Loader } from '../../../../ui-components';
import Map from './Map';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 80px)',
  },
});

function MapContainer() {
  const {
    map: { isMapLoading, points },
    settings: {
      values: { simplifyTolerance },
    },
  } = useGlobalStore();

  const classes = useStyles();

  if (isMapLoading) {
    return (
      <div className={classes.wrap}>
        <Loader />
      </div>
    );
  }

  if (points) {
    return (
      <Map points={points} simplifyTolerance={parseFloat(simplifyTolerance)} />
    );
  }

  return null;
}

export default MapContainer;
