import React from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useIntl } from 'react-intl';
import {
  updatePartialUploadName,
  updatePartialAuthToken,
} from '../../state/updatePartialUpload';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import { Dialog } from '../../../../ui-components';
import Settings from './Settings';

function SettingsContainer() {
  const {
    dispatch,
    settings: { visible, values },
    progress: { isInProgress },
  } = useGlobalStore();

  const intl = useIntl();

  const { search } = useLocation();

  const { mode } = parse(search);

  const isDev = mode === 'dev';

  const maxConnections = navigator.hardwareConcurrency;

  const handleChange = e => {
    let value;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    dispatch({
      type: actions.SET_SETTINGS_VALUE,
      payload: {
        name: e.target.name,
        value,
      },
    });
  };

  const handleClose = () => {
    dispatch({ type: actions.HIDE_SETTINGS });
  };

  const handleOnUploadNameBlur = e => {
    try {
      updatePartialUploadName(values.uploadId, e.target.value);
    } catch (error) {
      dispatch(
        actions.showError(
          error,
          intl.formatMessage({
            id: 'settings.errorUpdatingUploadName',
            defaultMessage: 'Error updating upload name in db',
          }),
        ),
      );
    }
  };

  const handleOnAuthTokenBlur = e => {
    try {
      updatePartialAuthToken(values.uploadId, e.target.value);
    } catch (error) {
      dispatch(
        actions.showError(
          error,
          intl.formatMessage({
            id: 'settings.errorUpdatingAuthToken',
            defaultMessage: 'Error updating authToken in db',
          }),
        ),
      );
    }
  };

  return (
    <Dialog
      title={intl.formatMessage({
        id: 'settings.title',
        defaultMessage: 'Upload settings',
      })}
      open={visible}
      onClose={handleClose}
    >
      <Settings
        values={values}
        isDev={isDev}
        maxConnections={maxConnections}
        uploadIsInProgress={isInProgress}
        handleChange={handleChange}
        handleOnUploadNameBlur={handleOnUploadNameBlur}
        handleOnAuthTokenBlur={handleOnAuthTokenBlur}
      />
    </Dialog>
  );
}

export default SettingsContainer;
