/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import * as errorTypes from './errorTypes';

const requiredItems = ['latitude', 'longitude', 'make', 'model'];

const fileEntry = file => ({
  key: uuidv4(),
  path: file.path,
});

export const addToErrors = (errorState, type, key, files = []) => {
  const errors = errorState;

  errors.count = errors.count + 1 || 1;
  errors.byType = errors.byType || {};

  if (errors.byType[type]) {
    if (errors.byType[type][key]) {
      errors.byType[type][key].count += 1;
      errors.byType[type][key].files = errors.byType[type][key].files.concat(
        files.map(file => fileEntry(file)),
      );
    } else {
      errors.byType[type][key] = {
        count: 1,
        files: files.map(file => fileEntry(file)),
      };
    }
  } else {
    errors.byType[type] = {};
    errors.byType[type][key] = {
      count: 1,
      files: files.map(file => fileEntry(file)),
    };
  }

  return errors;
};

export default (errorState, file, metaData) => {
  let errors = { ...errorState };

  // Date could exist in different formats
  const datetime = metaData.datetime || metaData.datetimeoriginal;

  if (!datetime) {
    errors = addToErrors(errors, errorTypes.META_MISSING, 'datetime', [file]);
  }

  // Create errors for any missing required meta data
  requiredItems.forEach(key => {
    if (!metaData[key]) {
      errors = addToErrors(errors, errorTypes.META_MISSING, key, [file]);
    }
  });

  return errors;
};
