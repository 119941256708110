import React from 'react';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import UploadInfo from './UploadInfo';

function UploadInfoContainer() {
  const {
    upload: { isProcessingComplete, partialUpload, errors },
  } = useGlobalStore();

  if (!isProcessingComplete && !partialUpload) {
    return null;
  }

  return <UploadInfo hasErrors={!!errors.byType} />;
}

export default UploadInfoContainer;
