import React from 'react';
import { useGlobalStore } from '../../../App/redux/GlobalStore';
import { Typography } from '../../../ui-components';

function UploadStats() {
  const {
    upload: { totalFilesSize, uploadedFileSize },
  } = useGlobalStore();

  const sizeOfSelectedFiles = (totalFilesSize / 1000000).toFixed(2);
  const sizeOfUploadedFiles = (uploadedFileSize / 1000000).toFixed(2);
  const compressionRatio = (
    (1 - sizeOfUploadedFiles / sizeOfSelectedFiles) *
    100
  ).toFixed(2);

  return (
    <>
      <Typography variant="h2" component="h2" gutterBottom>
        Statistics
      </Typography>
      <Typography variant="body1">{`Size of selected files: ${sizeOfSelectedFiles} MB`}</Typography>

      {uploadedFileSize > 0 && (
        <>
          <Typography variant="body1">{`Size of uploaded files: ${sizeOfUploadedFiles} MB`}</Typography>
          <Typography variant="body1">{`Compression ratio: ${compressionRatio}`}</Typography>
        </>
      )}
    </>
  );
}

export default UploadStats;
