import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App/App';
import 'fix-date';
import * as serviceWorker from './App/serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://2541c27f8a1f42e59743cdb68a565ed1@o365832.ingest.sentry.io/5222682',
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      // https://hummingbirdtech.atlassian.net/browse/FE-147
      'Unexpected token < in JSON at position 0',
      'Token Refresh Error.',
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
