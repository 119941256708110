import isString from 'lodash/isString';
import db from '../services/db';

export default id => {
  if (!isString(id)) {
    throw new Error('id should be a string');
  }

  return db.uploads
    .where('id')
    .equals(id)
    .delete();
};
