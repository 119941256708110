/* eslint-disable no-param-reassign */
import format from 'date-fns/format';
import isString from 'lodash/isString';
import db from '../services/db';

export const updatePartialUploadCount = id => {
  if (!isString(id)) {
    throw new Error('id should be a string');
  }

  return db.uploads
    .where('id')
    .equals(id)
    .modify((value, ref) => {
      ref.value = {
        ...value,
        filesUploadedCount: ref.value.filesUploadedCount + 1,
      };
    });
};

export const updatePartialUploadName = (id, uploadName) => {
  if (!isString(id) || !isString(uploadName)) {
    throw new Error('id and uploadName should be strings');
  }

  return db.uploads
    .where('id')
    .equals(id)
    .modify((value, ref) => {
      ref.value = {
        ...value,
        uploadName,
      };
    });
};

export const updatePartialAuthToken = (id, authToken) => {
  if (!isString(id) || !isString(authToken)) {
    throw new Error('id and authToken should be strings');
  }

  return db.uploads
    .where('id')
    .equals(id)
    .modify((value, ref) => {
      ref.value = {
        ...value,
        authToken,
      };
    });
};

export const updatePartialUploadAsCompleted = id => {
  if (!isString(id)) {
    throw new Error('id should be a string');
  }

  return db.uploads
    .where('id')
    .equals(id)
    .modify((value, ref) => {
      ref.value = {
        ...value,
        endDate: format(new Date(), 'yyyy-MM-dd hh-mm-ss'),
        complete: true,
      };
    });
};

export const updatePartialUploadManifest = (id, manifest) => {
  if (!isString(id)) {
    throw new Error('id should be a string');
  }
  return db.uploads
    .where('id')
    .equals(id)
    .modify((value, ref) => {
      ref.value = {
        ...value,
        manifest,
      };
    });
};
