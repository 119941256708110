import React from 'react';
import {
  MapContainer as LeafletMap,
  LayersControl,
  ZoomControl,
  ScaleControl,
  GeoJSON,
} from 'react-leaflet';
import { geoJSON } from 'leaflet';
import GoogleTiles from 'react-leaflet-google-layer';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '../../../ui-components';

const getMapStyle = () => ({
  color: 'rgb(255, 165, 0)',
  fillColor: 'rgba(255, 165, 0, 0.65)',
  weight: 2,
});

const useStyles = makeStyles(theme => ({
  wrap: {
    marginTop: theme.spacing(2),
  },
}));

const LONDON_BOUNDS = [
  [51.505, 0.2],
  [51.505, -0.4],
];
const KEY = process.env.GMAP_KEY;

const getBounds = flightPath => {
  function convertBounds(boundary) {
    try {
      return geoJSON(boundary).getBounds();
    } catch (e) {
      return undefined;
    }
  }

  if (flightPath?.geometries?.length) {
    return convertBounds(flightPath.geometries);
  }
  if (flightPath?.type === 'MultiPoint') {
    return convertBounds(flightPath);
  }
  return LONDON_BOUNDS;
};

function MapFlightPath({ flightPath }) {
  const classes = useStyles();

  if (!KEY) {
    // eslint-disable-next-line no-console
    console.error(
      'No GMAP_KEY found. Please add to .env file in project root.',
    );
  }

  const bounds = getBounds(flightPath);

  return (
    <Box className={classes.wrap}>
      <LeafletMap
        zoom={18}
        bounds={bounds}
        zoomControl={false}
        attributionControl={false}
        style={{ height: 'calc(100vh - 80px)' }}
        scrollWheelZoom={false}
      >
        <LayersControl collapsed={false}>
          <GoogleTiles
            googleMapsLoaderConf={{
              KEY,
            }}
            type="satellite"
          />

          {flightPath && (
            <GeoJSON data={flightPath} style={() => getMapStyle()} />
          )}
        </LayersControl>

        <ScaleControl position="bottomright" />
        <ZoomControl position="bottomright" />
      </LeafletMap>
    </Box>
  );
}

MapFlightPath.propTypes = {
  flightPath: PropTypes.objectOf(PropTypes.object),
};

MapFlightPath.defaultProps = {
  flightPath: null,
};

export default React.memo(MapFlightPath);
