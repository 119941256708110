import { booleanContains, convex, point, transformScale } from '@turf/turf';

function extractFieldFlightPath(fieldGeometry, flightPath) {
  // simplify shape of a field
  const simplifiedFieldGeometry = convex(fieldGeometry);

  // enlarge field geometry fot 20%
  const scaledFieldPolygon = transformScale(simplifiedFieldGeometry, 1.2, {
    origin: 'center',
  });

  // collect all points which contains outline polygon
  const fieldFlownPath = flightPath.filter(p =>
    booleanContains(scaledFieldPolygon, point(p)),
  );

  return fieldFlownPath;
}

export default extractFieldFlightPath;
