import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import BaseInputLabel from '@material-ui/core/InputLabel';

function InputLabel({ children, className, htmlFor }) {
  return (
    <BaseInputLabel className={className} htmlFor={htmlFor}>
      {children}
    </BaseInputLabel>
  );
}

InputLabel.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

InputLabel.defaultProps = {
  className: null,
  htmlFor: null,
};

export default InputLabel;
