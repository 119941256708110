import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import ProcessNotifications from '../ProcessNotifications/Notifications';
import Fields from '../Fields/Fields';
import { Divider, Tabs } from '../../../../ui-components';

const { Tab, TabPanel } = Tabs;

const useStyles = makeStyles({
  tab: {
    minWidth: ({ hasErrors }) => (hasErrors ? '50%' : '100%'),
  },
});

function UploadTabs({ hasErrors }) {
  const intl = useIntl();
  const [value, setValue] = useState(hasErrors ? '1' : '2');

  const classes = useStyles({ hasErrors });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Divider />
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        testid="tabs-uploadinfo"
      >
        {hasErrors && <Tab label="Errors" value="1" className={classes.tab} />}
        <Tab
          label={intl.formatMessage({
            id: 'upload.fields',
            defaultMessage: 'Fields',
          })}
          value="2"
          className={classes.tab}
        />
        {/* TODO: Additional sensor / upload info to be added  */}
        {/* <Tab label="Info" value="3" className={classes.tab} /> */}
      </Tabs>

      {hasErrors && (
        <TabPanel value={value} index="1" testid="panel-errors">
          <ProcessNotifications />
        </TabPanel>
      )}

      <TabPanel value={value} index="2" testid="panel-fields">
        <Fields />
      </TabPanel>

      {/* TODO: Additional sensor / upload info to be added  */}
      {/* <TabPanel value={value} index="3" testid="panel-info">
        <p>More info</p>
      </TabPanel> */}
    </>
  );
}

UploadTabs.propTypes = {
  hasErrors: PropTypes.bool,
};

UploadTabs.defaultProps = {
  hasErrors: false,
};

export default UploadTabs;
