import React, { useEffect } from 'react';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import getUploadToken from '../../state/getUploadToken';
import getPartialUploadData from '../../state/getPartialUploadData';
import { Box, Loader } from '../../../../ui-components';
import UploadHeader from './UploadHeader';
import UploadFormContainer from './UploadFormContainer';
import useLastLogin from '../../../../utils/useLastLogin';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 236,
  },
}));

function Upload() {
  const lastLogin = useLastLogin();
  const config = useConfig();
  const {
    dispatch,
    settings: {
      values: { bucket, authToken: tokenSettings },
    },
    upload: { isFetching },
  } = useGlobalStore();

  const { id } = useParams();

  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      let uploadToken;

      dispatch({ type: actions.FETCHING_UPLOAD });

      if (!tokenSettings) {
        try {
          const tokenResponse = await getUploadToken(config.apiUrl);
          uploadToken = get(tokenResponse, 'token');
        } catch (error) {
          dispatch({ type: actions.FETCHING_UPLOAD_FAIL });
          dispatch(
            actions.showError(
              error,
              'Error fetching auth token from /upload/token',
            ),
          );
        }
      }

      try {
        const {
          partialUpload,
          manifest,
          progress,
          points,
        } = await getPartialUploadData(
          bucket,
          uploadToken || tokenSettings,
          id,
        );

        dispatch({
          type: actions.FETCHING_UPLOAD_SUCCESS,
          payload: {
            partialUpload,
            manifest,
            progress,
            points,
          },
        });
      } catch (error) {
        Sentry.captureException(
          new Error(
            `Error Fetching Partial Upload Data.  Last login: ${lastLogin}`,
          ),
        );
        let message = 'Error fetching partial upload data';

        dispatch({ type: actions.FETCHING_UPLOAD_FAIL });

        if (error.response && error.response.status === 401) {
          message = 'This uploadToken has expired.';
        }

        dispatch(actions.showError(error, message));
      }
    }
    if (id && id !== 'new') {
      fetchData();
    }
  }, [dispatch, id, bucket, tokenSettings]);

  return (
    <Box className={classes.root}>
      {isFetching && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {!isFetching && (
        <>
          <UploadHeader />
          <UploadFormContainer />
        </>
      )}
    </Box>
  );
}

export default Upload;
