import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '../../../ui-components';
import HelpStep from './HelpStep';

function HelpContentNew() {
  const intl = useIntl();
  return (
    <>
      <HelpStep
        step="1"
        title={intl.formatMessage({
          id: 'helpContent.findTheIncompleteUpload',
          defaultMessage: 'Find the incomplete upload',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.uploadsThatHaveBeenPausedOrInterrupted"
            defaultMessage="Uploads that have been paused or interrupted before completion will be visible on the Home screen under 'Uploads in progress'."
          />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.onceFoundClickOnTheUpload"
            defaultMessage="Once found, click on the upload in the list to be taken to the upload screen."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="2"
        title={intl.formatMessage({
          id: 'helpContent.reselectTheFilesToBeUploaded',
          defaultMessage: 'Reselect the files to be uploaded',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.theFilesMustBeReselectedBeforeUploadingCanRestart"
            defaultMessage="The files must be reselected before uploading can restart. Please drag & drop or click to select the correct folder as before."
          />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.whenSelectedTheAppWillVerifyTheFilesSelected"
            defaultMessage="When selected the app will verify the files selected to make sure they match the incomplete upload. If the wrong folder is selected, you will see a warning display, click the clear button and reselect the correct folder."
          />
        </Typography>

        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.ifFilesHaveBeenAddedOrRemoved"
            defaultMessage="If files have been added or removed from the original folder, you will also see a warning. If this is a case, an upload cannot be restarted. Please create a new upload."
          />
        </Typography>
      </HelpStep>

      <HelpStep
        step="3"
        title={intl.formatMessage({
          id: 'helpContent.restartTheUpload',
          defaultMessage: 'Restart the upload',
        })}
      >
        <Typography variant="body2" paragraph>
          <FormattedMessage
            id="helpContent.withTheCorrectFolderSelected"
            defaultMessage="With the correct folder selected, the 'UPLOAD FILES' button will be enabled. As before, click it to restart the upload."
          />
        </Typography>
      </HelpStep>
    </>
  );
}
export default HelpContentNew;
