import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

export default function useSurveyQuery(surveyId, options) {
  const { apiUrl } = useConfig();

  return useQuery(
    ['survey', surveyId],
    () => get(apiUrl, `/v1/surveys/${surveyId}`),
    options,
  );
}
