import * as actions from './actions';

const initialProgressState = {
  isStarting: false,
  isStarted: false,
  isInProgress: false,
  isPaused: false,
  isPostUpload: false,
};

const progressReducer = (state, action) => {
  switch (action.type) {
    case actions.UPLOAD_STARTING:
      return {
        ...state,
        isStarting: true,
      };

    case actions.UPLOAD_STARTED:
      return {
        ...state,
        isStarting: false,
        isStarted: true,
        isInProgress: true,
        isPaused: false,
      };

    case actions.UPLOAD_PAUSED:
      return {
        ...state,
        isStarting: false,
        isStarted: false,
        isPaused: true,
      };

    case actions.UPLOAD_RESUMED:
      return {
        ...state,
        isStarting: true,
      };

    case actions.UPLOAD_COMPLETE:
      return {
        ...state,
        isStarting: false,
        isStarted: false,
        isInProgress: false,
        isPaused: false,
      };

    case actions.POST_UPLOAD_COMPLETE:
      return {
        ...state,
        isPostUpload: true,
      };

    case actions.UPLOAD_ERROR:
      return {
        ...state,
        isStarting: false,
        isStarted: false,
        isInProgress: true,
        isPaused: true,
      };

    case actions.CLEAR:
      return initialProgressState;

    case actions.LOCATION_CHANGE:
      return initialProgressState;

    default:
      return state;
  }
};

export { initialProgressState, progressReducer };
