import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../../../../ui-components';
import FieldsTableRow from './FieldsTableRow';

const { Container, Head, Body, Row, Cell } = Table;

const useStyles = makeStyles(theme => ({
  cell: {
    padding: theme.spacing(0.5, 1),
  },
}));

function FieldsTable({
  columns,
  data,
  activeItem,
  handleRowClick,
  handleFieldDateChange,
  fallbackText,
}) {
  const classes = useStyles();

  return (
    <Container>
      <Table dense>
        <Head>
          <Row>
            {columns.map(column => (
              <Cell key={column.key} className={classes.cell}>
                {column.name}
              </Cell>
            ))}
          </Row>
        </Head>
        <Body>
          {data.length > 0 &&
            data.map(item => (
              <FieldsTableRow
                key={item.id}
                item={item}
                activeItem={activeItem}
                columns={columns}
                handleRowClick={handleRowClick}
                handleFieldDateChange={handleFieldDateChange}
              />
            ))}
          {data.length === 0 && (
            <Row>
              <Cell colSpan={columns.length} align="center">
                {fallbackText}
              </Cell>
            </Row>
          )}
        </Body>
      </Table>
    </Container>
  );
}

FieldsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  activeItem: PropTypes.string,
  handleRowClick: PropTypes.func.isRequired,
  handleFieldDateChange: PropTypes.func.isRequired,
  fallbackText: PropTypes.node.isRequired,
};

FieldsTable.defaultProps = {
  data: [],
  activeItem: null,
};

export default FieldsTable;
