import React, { useState, useMemo } from 'react';
import get from 'lodash/get';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import { useLink } from 'valuelink';
import { format, subMonths, addDays } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useFlightListQuery } from '../../../../services/survey';
import { ROLES } from '../../../../utils/constants';
import { sortByDate } from '../../../../utils/sort';
import {
  DatePicker,
  Grid,
  Input,
  Typography,
  Checkbox,
  Table,
} from '../../../../ui-components';
import UploadsTable from './UploadsTable';
import FlightRow from './FlightRow';

const { Row, Cell } = Table;

const useStyles = makeStyles(({ spacing }) => ({
  checkboxContainer: {
    marginTop: spacing(2),
  },
}));

const allUploadsColumns = [
  {
    key: 'id',
    name: <FormattedMessage id="uploads.uploadId" defaultMessage="Upload ID" />,
  },
  {
    key: 'created_at',
    name: (
      <FormattedMessage id="uploads.startDate" defaultMessage="Start date" />
    ),
  },
  {
    key: 'updated_at',
    name: <FormattedMessage id="uploads.endDate" defaultMessage="End date" />,
  },
  {
    key: 'upload_status',
    name: <FormattedMessage id="uploads.status" defaultMessage="Status" />,
  },
  {
    key: 'farm',
    name: <FormattedMessage id="uploads.farm" defaultMessage="Farm" />,
  },
  {
    key: 'field_count',
    name: <FormattedMessage id="uploads.fields" defaultMessage="Fields" />,
  },
  {
    key: 'created_by',
    name: <FormattedMessage id="uploads.uploadBy" defaultMessage="Upload by" />,
  },
  {
    key: 'new_tab',
    name: '',
  },
];

function UploadsAll() {
  const classes = useStyles();
  const { user } = useAuth();

  const [
    showOnlyFlightsWithoutFields,
    setShowOnlyFlightsWithoutFields,
  ] = useState(false);
  const [filterValue, setFilterValue] = useState(() =>
    get(user, 'role') === ROLES.PILOT ? get(user, 'email', '') : '',
  );
  const today = new Date();
  const $startDate = useLink(subMonths(today, 1));
  const $endDate = useLink(addDays(today, 1));

  const { data: uploads = [], isLoading } = useFlightListQuery(
    format($startDate.value, 'yyyy-MM-dd'),
    format($endDate.value, 'yyyy-MM-dd'),
  );

  const sortedUploads = useMemo(
    () => sortByDate(uploads, 'created_at', 'desc'),
    [uploads],
  );

  const handleFilterChange = e => setFilterValue(e.target.value);

  return (
    <>
      <Typography variant="h3" component="h2">
        <FormattedMessage
          id="uploads.allUploads"
          defaultMessage="All uploads"
        />
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <DatePicker
            label={<FormattedMessage id="uploads.from" defaultMessage="From" />}
            $value={$startDate}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            label={<FormattedMessage id="uploads.to" defaultMessage="To" />}
            $value={$endDate}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            label={
              <FormattedMessage id="uploads.filter" defaultMessage="Filter" />
            }
            name="filter"
            value={filterValue}
            onChange={handleFilterChange}
            isWrapped
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.checkboxContainer}>
            <Checkbox
              name="without-fields"
              checked={showOnlyFlightsWithoutFields}
              onChange={() => setShowOnlyFlightsWithoutFields(prev => !prev)}
              label={
                <FormattedMessage
                  id="uploads.filter-no-fields"
                  defaultMessage="Show Only Flights without fields"
                />
              }
            />
          </div>
        </Grid>
      </Grid>

      <UploadsTable columns={allUploadsColumns} isFetching={isLoading}>
        {sortedUploads.length > 0 ? (
          sortedUploads.map(item => (
            <FlightRow
              flightItem={item}
              filter={filterValue}
              key={item.id}
              showOnlyFlightsWithoutFields={showOnlyFlightsWithoutFields}
            />
          ))
        ) : (
          <Row>
            <Cell colSpan={allUploadsColumns.length} align="center">
              <FormattedMessage
                id="uploads.noRecentlyCompletedUploads"
                defaultMessage="No recently completed uploads"
              />
            </Cell>
          </Row>
        )}
      </UploadsTable>
    </>
  );
}

export default UploadsAll;
