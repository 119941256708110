export default (theme, status) => {
  switch (status) {
    case 'info':
      return theme.palette.info.light;

    case 'warning':
      return theme.palette.warning.dark;

    case 'error':
      return theme.palette.error.main;

    case 'success':
      return theme.palette.success.light;

    default:
      return theme.palette.info.light;
  }
};
