import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  container: {
    height: 400,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
      height: 'calc(100vh - 80px)',
    },
  },
}));

function MapLayout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>{children}</div>
    </div>
  );
}

MapLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MapLayout;
