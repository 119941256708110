/* eslint-disable no-console */
import format from 'date-fns/format';
import isString from 'lodash/isString';
import db from '../services/db';

export default ({ id, uploadName, filesUploadedCount, totalFiles }) => {
  if (!isString(id) || !isString(uploadName)) {
    throw new Error('id and uploadName should be strings');
  }

  if (
    typeof filesUploadedCount !== 'number' ||
    typeof totalFiles !== 'number'
  ) {
    throw new Error('filesUploadedCount and totalFiles should be numbers');
  }

  return db.uploads.put({
    id,
    uploadName,
    startDate: format(new Date(), 'yyyy-MM-dd hh-mm-ss'),
    filesUploadedCount,
    totalFiles,
    complete: false,
    manifest: {},
  });
};
