import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ConditionalWrap, Container } from '../ui-components';
import Header from './components/Header/Header';

const useStyles = makeStyles({
  wrap: {
    position: 'relative',
  },
});

function Layout({ children, maxWidth, className, container }) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Header />
      <ConditionalWrap
        condition={container}
        wrap={child => <Container maxWidth={maxWidth}>{child}</Container>}
      >
        <div className={classes.wrap}>{children}</div>
      </ConditionalWrap>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  container: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  ]),
  className: PropTypes.string,
};

Layout.defaultProps = {
  maxWidth: false,
  container: false,
  className: null,
};

export default Layout;
