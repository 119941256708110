import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLinkType } from '../../../../types';
import getStatusColor from '../../../../utils/getStatusColor';
import {
  Checkbox,
  Divider,
  StatusIcon,
  Typography,
} from '../../../../ui-components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    color: ({ status }) => getStatusColor(theme, status),
  },
  icon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
}));

function Message({
  title,
  message,
  status,
  $value,
  messageTestId,
  checkboxTestId,
}) {
  const classes = useStyles({ status });

  return (
    <>
      <Divider />
      <div className={classes.root} data-testid={messageTestId}>
        <StatusIcon status={status} className={classes.icon} />

        <div>
          {title && <Typography variant="h6">{title}</Typography>}
          {message && <Typography variant="body2">{message}</Typography>}
        </div>

        {$value && (
          <Checkbox name="override" testid={checkboxTestId} $value={$value} />
        )}
      </div>
    </>
  );
}

Message.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  $value: useLinkType,
  messageTestId: PropTypes.string,
  checkboxTestId: PropTypes.string,
};

Message.defaultProps = {
  title: null,
  message: null,
  status: null,
  $value: null,
  messageTestId: null,
  checkboxTestId: null,
};

export default Message;
