import { v4 as uuid } from 'uuid';
import addPoint from './addPoint';

export default manifest => {
  const { cameras, files } = manifest;

  let points = {};

  Object.keys(manifest.files).forEach(file => {
    const { metadata } = files[file];

    if (metadata) {
      const metaData = {
        datetime: files[file].metadata.datetime,
        ...cameras[files[file].metadata.camera],
        ...files[file].metadata.gps,
      };
      points = addPoint(points, metaData, uuid());
    }
  });

  return points;
};
