import {
  convex,
  featureCollection,
  intersect,
  point,
  transformScale,
} from '@turf/turf';

// Return geometry of covered area by drone, or returns NULL;
function getFiledFlownGeometry(fieldGeometry, fieldFlightPath) {
  // no geometry found
  if (fieldFlightPath.length < 3) {
    return null;
  }

  // make a polygon from flown points
  const fieldFlownGeometry = featureCollection(
    fieldFlightPath.map(p => point(p)),
  );

  const scaledFieldFlownGeometry = transformScale(
    convex(fieldFlownGeometry), // convert geometry to polygon
    1.05, // enlarge polygon around a field for 5%
    {
      origin: 'center',
    },
  );

  const intersectedFlownGeometry = intersect(
    scaledFieldFlownGeometry,
    fieldGeometry,
  );

  return intersectedFlownGeometry;
}

export default getFiledFlownGeometry;
