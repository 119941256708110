import isEqual from 'lodash/isEqual';
import XMP from 'xmp-js';
import xmlParser from 'fast-xml-parser';
import { isJpeg } from '../../../../utils/image';

export default async (manifest, file, metaData, padLength, fileIndex) => {
  const newManifest = manifest;
  const fileKey = fileIndex.toString().padStart(padLength, 0);

  newManifest.cameras = newManifest.cameras || {};
  newManifest.files = newManifest.files || {};

  newManifest.files[fileKey] = {
    'content-type': file.type || 'application/octet-stream',
    bytes: file.size,
    path: file.path,
    metadata: {
      cameraBandNames: null,
    },
  };

  if (isJpeg(file)) {
    const reader = new FileReader();
    reader.onload = e => {
      const xmp = new XMP(e.target.result);
      const rawXML = xmp.find();
      const xmpJsonString = rawXML ? xmlParser.parse(rawXML) : '';

      // matches the string between `Camera:BandName` and the following }} - SFR 2021-02-01

      const cameraBand = JSON.stringify(xmpJsonString)?.match(
        /(band)(.*?)(}})/gi,
      );

      const cameraBandSubString = cameraBand?.length > 0 ? cameraBand[0] : null;

      // Extracts the array from the substring and converts into a `string[]` - SFR 2021-02-01

      const bandNames = cameraBandSubString
        ?.match(/(?<=\[)(.*?)(?=\])/gi)[0]
        .replace(/"/g, '')
        .split(',');

      if (bandNames)
        newManifest.files[fileKey].metadata.cameraBandNames = bandNames;
    };

    await reader.readAsDataURL(file);
  }

  if (!metaData) {
    return newManifest;
  }

  // Add camera to the cameras array
  const {
    make,
    model,
    serialnumber,
    software,
    exifimagewidth,
    imagewidth,
    exifimageheight,
    imageheight,
  } = metaData;

  const cameraObject = {
    make: make || null,
    model: model || null,
    'serial-number': serialnumber || null,
    'software-version': software || null,
    'pixel-x-dimension': exifimagewidth || imagewidth || null,
    'pixel-y-dimension': exifimageheight || imageheight || null,
  };

  let camIndex = 0;
  let exists = false;

  Object.keys(newManifest.cameras).forEach((item, index) => {
    if (isEqual(newManifest.cameras[item], cameraObject)) {
      exists = true;
      camIndex = index;
    }
  });

  if (!exists) {
    camIndex = Object.keys(newManifest.cameras).length;
    newManifest.cameras[Object.keys(newManifest.cameras).length] = cameraObject;
  }

  // Create the file entry in the manifest
  const {
    latitude,
    longitude,
    gpsaltitude,
    datetime,
    datetimeoriginal,
  } = metaData;

  newManifest.files[fileKey].metadata = {
    gps: {
      latitude: latitude || null,
      longitude: longitude || null,
      altitude: gpsaltitude || null,
    },
    datetime: datetime || datetimeoriginal || null,
    camera: camIndex,
  };

  return newManifest;
};
