import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  LoginRedirect,
  LogoutRedirect,
  PrivateRoute,
} from '@hummingbirdtechgroup/wings-auth';
import NotFound from './NotFound';
import Uploads from '../modules/Upload/UploadRouter';
import UploadTester from '../modules/Upload/scenes/UploadTester';
import Report from '../modules/Report/scenes/Report';

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER', 'PILOT']}
        path="/upload"
        render={() => <Uploads />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER', 'PILOT']}
        exact
        path="/report/:flightId"
        render={() => <Report />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER', 'PILOT']}
        path="/test"
        render={() => <UploadTester />}
      />
      <Route exact path="/logout">
        <LogoutRedirect />
      </Route>
      <Route path="/login">
        <LoginRedirect />
      </Route>
      <Route path="/">
        <Redirect to="/upload" />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Routes;
