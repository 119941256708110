import get from 'lodash/get';
import config from '../config';

const excludedIfInPath = get(config, 'common.excludedIfInPath');

export default files => {
  const sortedFiles = files.sort((a, b) => a.path.localeCompare(b.path));

  return Array.from(sortedFiles).filter(file => {
    let include = true;

    // Exclude files beginning with a .
    if (file.name.substring(0, 1) === '.') {
      include = false;
    }

    // Exclude files based on excludedIfInPath. e.g. '/.thumbs/'
    if (excludedIfInPath.some(str => file.path.includes(str))) {
      include = false;
    }

    return include;
  });
};
