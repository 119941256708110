import axios from 'axios';

function bucketClient({
  method = 'get',
  bucket,
  headers,
  params,
  data,
  objectName,
  cancelToken,
}) {
  let url = null;

  if (method === 'post') {
    url = `https://www.googleapis.com/upload/storage/v1/b/${bucket}/o`;
  }

  if (method === 'get') {
    url = `https://www.googleapis.com/storage/v1/b/${bucket}/o`;
  }

  if (!url) {
    // eslint-disable-next-line no-console
    console.warn('No google cloud storage path found in env.js');
  }

  if (objectName) {
    url = `${url}/${objectName}`;
  }

  const request = {
    method,
    url,
    headers,
    params,
    data,
    cancelToken,
  };

  return axios(request);
}

export default bucketClient;
