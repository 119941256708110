import React from 'react';
import PropTypes from 'prop-types';
import {
  CircleMarker,
  Polyline,
  LayerGroup,
  LayersControl,
} from 'react-leaflet';

const pointColor = [
  '#f44336',
  '#2196f3',
  '#ff9800',
  '#7e57c2',
  '#ffee58',
  '#ba68c8',
];

function MapFlightPathsLayer({ index, name, flightPath, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LayersControl.Overlay name={name} {...props}>
      <LayerGroup>
        <Polyline
          color={pointColor[index]}
          positions={flightPath.map(p => p.point)}
        />
        {flightPath.map(data => {
          return (
            <CircleMarker
              key={`${name}-${data.key}`}
              radius={4}
              color={pointColor[index]}
              center={[data.point[0], data.point[1]]}
            />
          );
        })}
      </LayerGroup>
    </LayersControl.Overlay>
  );
}

MapFlightPathsLayer.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  flightPath: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MapFlightPathsLayer;
