import Dexie from 'dexie';

const DB_NAME = 'HummingbirdUploaderStore';

const db = new Dexie(DB_NAME);

db.version(1).stores({
  uploads:
    'id, uploadName, startDate, endDate, filesUploadedCount, totalFiles, complete, manifest, generatedManifest',
});

export default db;
