import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';

function SentryScope() {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const { role, email, name, id } = user;
      Sentry.configureScope(scope => {
        scope.setUser({ email, username: name, id });
        scope.setTag('user_role', role);
      });
    }
  }, [user]);

  return null;
}

export default SentryScope;
