import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from '../../../ui-components';
import { YYYY_MM_DD_HH_MM } from '../../../utils/constants';
import { formatDate } from '../../../utils/date';

const { Container, Body, Row, Cell } = Table;

const NO_DATA = '-';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  wrap: {
    margin: theme.spacing(2, 0, 4, 0),
  },
}));

function UploadMetadata({ flight, uploaderEmail }) {
  const classes = useStyles();

  if (!flight) return null;
  return (
    <Box className={classes.wrap}>
      <Container>
        <Table>
          <Body>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.uploadId"
                  defaultMessage="Upload ID"
                />
              </Cell>
              <Cell>{flight.id}</Cell>
            </Row>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.startDate"
                  defaultMessage="Start Date"
                />
              </Cell>
              <Cell>{formatDate(flight.created_at, YYYY_MM_DD_HH_MM)}</Cell>
            </Row>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.finishDate"
                  defaultMessage="Finish Date"
                />
              </Cell>
              <Cell>
                {flight.upload_status === 'Completed'
                  ? formatDate(flight.updated_at, YYYY_MM_DD_HH_MM)
                  : NO_DATA}
              </Cell>
            </Row>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.status"
                  defaultMessage="Upload Status"
                />
              </Cell>
              <Cell>{flight.upload_status}</Cell>
            </Row>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.uploadedBy"
                  defaultMessage="Uploaded by"
                />
              </Cell>
              <Cell>{uploaderEmail}</Cell>
            </Row>
            <Row>
              <Cell className={classes.bold}>
                <FormattedMessage
                  id="upload.list.filename"
                  defaultMessage="File name"
                />
              </Cell>
              <Cell>{flight.filename}</Cell>
            </Row>
          </Body>
        </Table>
      </Container>
    </Box>
  );
}

UploadMetadata.propTypes = {
  flight: PropTypes.objectOf(PropTypes.object).isRequired,
  uploaderEmail: PropTypes.string.isRequired,
};

export default UploadMetadata;
