import React from 'react';
import { Box, Card, Grid } from '../../../ui-components';
import Layout from '../../../App/Layout';
import Uploads from '../components/Uploads/Uploads';

function UploadsScene() {
  return (
    <Layout maxWidth="lg" container>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box spacing={{ my: 4 }}>
            <Card>
              <Uploads />
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default UploadsScene;
