import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import BaseAlert from '@material-ui/lab/Alert';

function Alert({ children, severity }) {
  return (
    <div data-testid={`alert-${severity}`}>
      <BaseAlert severity={severity}>{children}</BaseAlert>
    </div>
  );
}

Alert.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Alert.defaultProps = {
  severity: 'error',
};

export default Alert;
