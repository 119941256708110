import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import formatISO from 'date-fns/formatISO';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import FieldsTable from './FieldsTable';
import { notifySlackUploadInfo } from '../../../../utils/notifySlack';
import {
  getFiledFlownGeometry,
  findPercentAreaAisB,
  stringifyGeometry,
  extractPoints,
  extractFieldFlightPath,
} from '../../services/fileProcessing/geometryUtils';

// adding startDate to baseZones query as per how the query is handled in the backend as per the following link and code
// will filter the the response to include only end dates that start that are either the startDate itself or newer after the startDate
// resulting in the zones which have an expiration date prior from that date to not be fetched from the backend and this way
// won't generate surveys for the expired baseZones.
// Finally, fields with endDate as null (default state for when they are created) will still be fetched, only fields with
// set endDate will be filtered out.

// https://github.com/HummingbirdTechGroup/farm-management/blob/develop/farm_management/graphql_api/schema/base_zone.py#L210-L218

const GET_NEAREST_FIELDS = gql`
  query Upload_NearestFields($geometry: JSONString, $startDate: Date) {
    baseZones(geometry: $geometry, startDate: $startDate) {
      id
      geometry
      endDate
      area
      field {
        id
        name
      }
    }
  }
`;

function createSurveyPayloadData(fields, points, manifest) {
  const getInitialSurveyData = fieldId => ({
    field_id: fieldId,
    captured_at: formatISO(
      manifest?.files?.[Object.keys(manifest.files)[0]]?.metadata?.datetime ??
        new Date(),
    ),
    source_type: 'UAV',
    enabled: true,
  });

  const surveyDataPayload = {};

  fields.forEach(field => {
    surveyDataPayload[field.id] = {
      fieldName: field.name,
      surveyBaseData: getInitialSurveyData(field.id),
      include: true,
    };
  });

  return surveyDataPayload;
}

function createSurveyCoveragePayloadData(fields, points) {
  const surveyCoverageDataPayload = {};

  fields.forEach(({ geometry, id }) => {
    const fieldFlightPath = extractFieldFlightPath(geometry, points);
    const fieldFlownGeometry = getFiledFlownGeometry(geometry, fieldFlightPath);
    const percent = findPercentAreaAisB(fieldFlownGeometry, geometry);

    surveyCoverageDataPayload[id] = {
      geometry: fieldFlownGeometry,
      flightPath: fieldFlightPath,
      percent,
    };
  });

  return surveyCoverageDataPayload;
}

const TODAY = new Date(Date.now()).toISOString().split('T')[0];

function Fields() {
  const {
    dispatch,
    upload: { surveyData, manifest, coverageData },
    map: { points: rawPoints, activeField },
  } = useGlobalStore();

  const points = extractPoints(rawPoints);
  const geometry = stringifyGeometry(points);

  const { data, loading } = useQuery(GET_NEAREST_FIELDS, {
    skip: !points || !geometry,
    variables: { geometry, startDate: TODAY },
  });

  useEffect(() => {
    if (!loading) {
      const baseZones = data?.baseZones ?? [];

      // Notify Slack
      const fieldIds = baseZones?.map(baseZone => baseZone.id) ?? [];

      notifySlackUploadInfo(
        undefined,
        `Fields Detected (${fieldIds.length}): ${fieldIds.toString()}`,
      );

      // save base zone data into the store
      const matchingBaseZones = baseZones.map(baseZone => ({
        ...baseZone,
        geometry: JSON.parse(baseZone?.geometry),
      }));

      dispatch({
        type: actions.SET_MATCHING_BASE_ZONES,
        payload: matchingBaseZones,
      });

      if (!matchingBaseZones.filter(baseZone => baseZone.field).length) return;

      // Build the survey data payload, and store it in the store
      const fields = matchingBaseZones.map(baseZone => ({
        ...baseZone.field,
        geometry: baseZone.geometry,
      }));

      const surveyDataPayload = createSurveyPayloadData(
        fields,
        points,
        manifest,
      );

      dispatch({
        type: actions.SET_SURVEY_DATA,
        payload: surveyDataPayload,
      });

      const surveyCoverageDataPayload = createSurveyCoveragePayloadData(
        fields,
        points,
      );

      dispatch({
        type: actions.SET_SURVEY_COVERAGE_DATA,
        payload: surveyCoverageDataPayload,
      });
    }
  }, [data, dispatch, loading]);

  const surveyFields =
    typeof surveyData === 'object' &&
    Object.keys(surveyData).map(key => {
      const { fieldName, surveyBaseData } = surveyData[key];
      return {
        id: key,
        name: fieldName,
        capturedAt: surveyBaseData.captured_at,
        coverage: `${Math.round(coverageData[key].percent)}%`,
      };
    });

  if (!surveyFields) {
    return null;
  }

  const handleRowClick = item => {
    dispatch({ type: actions.SET_ACTIVE_FIELD, payload: item.id });
  };
  const handleFieldDateChange = (fieldId, capturedAt) => {
    dispatch({
      type: actions.SET_SURVEY_CAPTURED_TIME,
      payload: {
        fieldId,
        capturedAt,
      },
    });
  };

  const fieldsColumns = [
    {
      key: 'name',
      name: (
        <FormattedMessage id="upload.fieldName" defaultMessage="Field name" />
      ),
    },
    {
      key: 'capturedAt',
      name: (
        <FormattedMessage
          id="upload.capturedAt"
          defaultMessage="Capture date"
        />
      ),
    },
    { key: 'coverage', name: '' },
    {
      key: 'include',
      name: '',
    },
  ];

  return (
    <FieldsTable
      columns={fieldsColumns}
      data={surveyFields}
      activeItem={activeField}
      fallbackText={
        <FormattedMessage
          id="upload.noFieldsToDisplay"
          defaultMessage="No fields to display"
        />
      }
      handleRowClick={handleRowClick}
      handleFieldDateChange={handleFieldDateChange}
    />
  );
}

export default Fields;
