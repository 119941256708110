import { LineUtil } from 'leaflet';

export default (points, tolerance) => {
  const flightPaths = {};

  if (tolerance === 0 || Number.isNaN(tolerance)) {
    // eslint-disable-next-line no-param-reassign
    tolerance = 1e-12;
  }

  Object.keys(points).forEach(type => {
    flightPaths[type] = LineUtil.simplify(
      points[type].map(po => po.point),
      tolerance,
      true,
    ).map((po, key) => ({ key, point: [po.x, po.y] }));
  });

  return flightPaths;
};
