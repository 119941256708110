import { polyline } from 'leaflet';

export default flightPaths => {
  const points = Object.keys(flightPaths).reduce((acc, type) => {
    return acc.concat(flightPaths[type].map(p => p.point));
  }, []);

  try {
    return polyline(points).getBounds();
  } catch (e) {
    return undefined;
  }
};
