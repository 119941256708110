import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { FormattedMessage } from 'react-intl';
import { Dropzone, Progress, Typography } from '../../../../ui-components';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[400],
    minHeight: '94px',
  },
  progress: {
    marginBottom: theme.spacing(1),
  },
  done: {
    position: 'relative',
    height: 34,
    width: 34,
    fontSize: 30,
    marginBottom: theme.spacing(1),
    '& > *': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& > svg': {
      top: 2,
      left: 1,
    },
  },
  error: {
    '& > svg': {
      left: 2,
    },
  },
}));

function UploadDropzone({
  isFilesSelected,
  filesProcessedCount,
  totalFiles,
  verifyError,
  isComplete,
  handleFilesAdded,
}) {
  const classes = useStyles();

  const progress = Math.round((filesProcessedCount / totalFiles) * 100);

  if (isFilesSelected && !verifyError) {
    return (
      <div className={classes.container}>
        {filesProcessedCount + 2 < totalFiles ? (
          <>
            <Progress
              type="circular"
              variant="determinate"
              value={progress}
              size={34}
              className={classes.progress}
            />
            <Typography variant="body1" testid="type-processing">
              <FormattedMessage
                id="upload.pleaseWaitProcessingXOfYFiles"
                defaultMessage="Please wait, processing {filesProcessedCount, number} of {totalFiles, number} files"
                values={{ filesProcessedCount, totalFiles }}
              />
            </Typography>
          </>
        ) : (
          <>
            <div className={classes.done}>
              <Progress
                type="circular"
                variant="determinate"
                value={100}
                size={34}
                className={classes.progress}
              />
              <DoneIcon color="primary" fontSize="inherit" />
            </div>
            <Typography variant="body1" testid="type-files-selected">
              <FormattedMessage
                id="upload.XFilesHaveBeenSelected"
                defaultMessage="{totalFiles, plural, one {# file has} other {# files have}} been selected"
                values={{ totalFiles }}
              />
            </Typography>
          </>
        )}
      </div>
    );
  }

  if (isFilesSelected && verifyError) {
    return (
      <div className={classes.container}>
        <div className={cx(classes.done, classes.error)}>
          <Progress
            type="circular"
            variant="determinate"
            value={100}
            size={34}
            className={classes.progress}
          />
          <PriorityHighIcon color="primary" fontSize="inherit" />
        </div>
        <Typography variant="body1" testid="type-files-mismatch">
          <FormattedMessage
            id="upload.theSelectedFilesDoNotMatch"
            defaultMessage="The selected files do not match"
          />
        </Typography>
      </div>
    );
  }

  return (
    <Dropzone
      directory="true"
      disabled={isFilesSelected || isComplete}
      onDrop={handleFilesAdded}
    />
  );
}

UploadDropzone.propTypes = {
  isFilesSelected: PropTypes.bool,
  filesProcessedCount: PropTypes.number,
  totalFiles: PropTypes.number,
  verifyError: PropTypes.string,
  isComplete: PropTypes.bool,
  handleFilesAdded: PropTypes.func.isRequired,
};

UploadDropzone.defaultProps = {
  isFilesSelected: false,
  filesProcessedCount: 0,
  totalFiles: 0,
  verifyError: null,
  isComplete: false,
};

export default UploadDropzone;
