import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  CONNECTIONS_MIN,
  CONNECTIONS_DEFAULT,
  COMPRESSION_MIN,
  COMPRESSION_MAX,
  COMPRESSION_DEFAULT,
  SIMPLIFY_DEFAULT,
} from '../../../../utils/constants';
import { Checkbox, Input, InputNumber } from '../../../../ui-components';

function UploadSettings({
  values,
  isDev,
  maxConnections,
  uploadIsInProgress,
  handleChange,
  handleOnUploadNameBlur,
  handleOnAuthTokenBlur,
}) {
  return (
    <form>
      {values.uploadId && (
        <Input
          label={
            <FormattedMessage
              id="settings.uploadId"
              defaultMessage="Upload ID"
            />
          }
          name="uploadId"
          value={values.uploadId}
          disabled
          isWrapped
          fullWidth
        />
      )}
      <Input
        label={
          <FormattedMessage
            id="settings.uploadName"
            defaultMessage="Upload name"
          />
        }
        name="uploadName"
        helperText={
          <FormattedMessage
            id="settings.uploadNameHelperText"
            defaultMessage="This will be auto-filled as the root folder of the selected files and can be edited here"
          />
        }
        value={values.uploadName}
        onChange={handleChange}
        onBlur={handleOnUploadNameBlur}
        isWrapped
        fullWidth
        useLocalStorage
      />
      <Input
        label={<FormattedMessage id="settings.token" defaultMessage="Token" />}
        name="authToken"
        helperText={
          <FormattedMessage
            id="settings.tokenHelperText"
            defaultMessage="Override auth token, for developer use only"
          />
        }
        value={values.authToken}
        onChange={handleChange}
        onBlur={handleOnAuthTokenBlur}
        isWrapped
        fullWidth
      />
      {isDev && (
        <>
          <Input
            label="Bucket name"
            name="bucket"
            value={values.bucket}
            helperText="For developer use only"
            disabled={uploadIsInProgress}
            onChange={handleChange}
            isWrapped
            fullWidth
            useLocalStorage
          />
          <InputNumber
            label={`Initial number of connections (1 - ${maxConnections})`}
            name="connections"
            type="number"
            value={values.connections}
            defaultValue={CONNECTIONS_DEFAULT}
            min={CONNECTIONS_MIN}
            max={maxConnections}
            disabled={uploadIsInProgress}
            onChange={handleChange}
            isWrapped
            fullWidth
            useLocalStorage
          />
          <InputNumber
            label="Initial compression level (1 - 9)"
            name="compression"
            type="number"
            value={values.compression}
            defaultValue={COMPRESSION_DEFAULT}
            min={COMPRESSION_MIN}
            max={COMPRESSION_MAX}
            disabled={uploadIsInProgress}
            onChange={handleChange}
            isWrapped
            fullWidth
            useLocalStorage
          />
          <InputNumber
            label="Flight path simplification tolerance"
            name="simplifyTolerance"
            value={values.simplifyTolerance}
            defaultValue={SIMPLIFY_DEFAULT}
            onChange={handleChange}
            noStepper
            isWrapped
            fullWidth
            useLocalStorage
          />

          <Checkbox
            label="Enable automatic tuning"
            name="fineTuningEnabled"
            checked={values.fineTuningEnabled}
            onChange={handleChange}
            disabled={uploadIsInProgress}
            useLocalStorage
          />

          <Checkbox
            label="Enable compression"
            name="compressionEnabled"
            checked={values.compressionEnabled}
            onChange={handleChange}
            disabled={uploadIsInProgress}
            useLocalStorage
          />
        </>
      )}
    </form>
  );
}

UploadSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object,
  isDev: PropTypes.bool,
  maxConnections: PropTypes.number.isRequired,
  uploadIsInProgress: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleOnUploadNameBlur: PropTypes.func.isRequired,
  handleOnAuthTokenBlur: PropTypes.func.isRequired,
};

UploadSettings.defaultProps = {
  values: {},
  isDev: false,
  uploadIsInProgress: false,
};

export default UploadSettings;
