import React from 'react';
import PropTypes from 'prop-types';
import {
  MapContainer as LeafletMap,
  LayersControl,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import getBounds from '../../services/map/getBounds';
import createFlightPaths from '../../services/map/createFlightPaths';
import MapFlightPathsLayer from './MapFlightPathsLayer';
import MapFieldsLayer from './MapFieldsLayer';
import MapFieldsFlownAreaLayer from './MapFieldsFlownAreaLayer';

function Map({ points, simplifyTolerance }) {
  const KEY = process.env.GMAP_KEY;
  const flightPaths = createFlightPaths(points, simplifyTolerance);
  const bounds = getBounds(flightPaths);

  if (!KEY) {
    // eslint-disable-next-line no-console
    console.error(
      'No GMAP_KEY found. Please add to .env file in project root.',
    );
  }

  if (Object.keys(bounds).length === 0) return null;

  return (
    <LeafletMap
      zoom={18}
      bounds={bounds}
      zoomControl={false}
      attributionControl={false}
      style={{ height: 'calc(100vh - 80px)' }}
    >
      <LayersControl collapsed={false}>
        <ReactLeafletGoogleLayer apiKey={KEY} type="satellite" />

        <MapFieldsLayer />

        {Object.keys(flightPaths).map((path, index) => {
          return (
            <MapFlightPathsLayer
              index={index}
              name={path}
              key={path}
              flightPath={flightPaths[path]}
              checked
            />
          );
        })}
      </LayersControl>

      <MapFieldsFlownAreaLayer flightPaths={flightPaths} />

      <ScaleControl position="bottomright" />

      <ZoomControl position="bottomright" />
    </LeafletMap>
  );
}

Map.propTypes = {
  points: PropTypes.shape({
    multispectral: PropTypes.arrayOf(PropTypes.any),
    rgb: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  simplifyTolerance: PropTypes.number.isRequired,
};

export default React.memo(Map);
