import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import { format, isEqual } from 'date-fns';
import { get } from 'lodash';
import { YYYY_MM_DD_HH_MM } from '../../../../utils/constants';
import { useUserQuery } from '../../../../services/user';
import { Table } from '../../../../ui-components';
import uuidRegexExp from '../../../../constants/uuidRegexExp';

const { Row, Cell } = Table;
const UNKNOWN = 'Unknown';

const formatDate = dateString =>
  dateString ? format(new Date(dateString), YYYY_MM_DD_HH_MM) : '-';

const checkIfFiltered = (item, filter) =>
  Object.keys(item).some(
    key =>
      typeof item[key] === 'string' &&
      item[key].toLowerCase().includes(filter.toLowerCase()),
  );

const isVisible = (showOnlyFlightsWithoutFields, flight, filter) =>
  !(
    (showOnlyFlightsWithoutFields && flight.field_count) ||
    !checkIfFiltered(flight, filter)
  );

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer',
  },
}));

const GET_FIELD = gql`
  query FlightRow_Farm($fieldId: ID!) {
    field(id: $fieldId) {
      id
      name
      farm {
        id
        name
      }
    }
  }
`;

const NO_DATE_VALUE = '-';

function FlightRow({ flightItem, filter, showOnlyFlightsWithoutFields }) {
  const classes = useStyles();
  const history = useHistory();
  const [flight, setFlight] = useState(flightItem);
  const { data: user } = useUserQuery(flightItem.created_by, {
    enabled:
      Boolean(flightItem.created_by) &&
      uuidRegexExp.test(flightItem.created_by),
  });

  const fieldId = get(flightItem, 'field_ids[0]');
  const { data: fieldData } = useQuery(GET_FIELD, {
    variables: { fieldId },
    skip: !fieldId,
  });

  useEffect(() => {
    if (user) setFlight(prev => ({ ...prev, created_by: user.email }));
    if (fieldData)
      setFlight(prev => ({ ...prev, farm: get(fieldData, 'field.farm.name') }));
  }, [user, fieldData]);

  const handleRowClick = () => history.push(`/report/${flightItem.id}`);
  const handleOpenExternal = () =>
    window.open(`/report/${flightItem.id}`, '_blank');

  return (
    isVisible(showOnlyFlightsWithoutFields, flight, filter) && (
      <Row
        className={classes.row}
        key={flight.id}
        hover
        role="button"
        tabIndex={-1}
        testid={`row-${flight.id}`}
      >
        <Cell onClick={handleRowClick}>{flight.id}</Cell>
        <Cell onClick={handleRowClick}>{formatDate(flight.created_at)}</Cell>
        <Cell onClick={handleRowClick}>
          {isEqual(new Date(flight.created_at), new Date(flight.updated_at))
            ? NO_DATE_VALUE
            : formatDate(flight.updated_at)}
        </Cell>
        <Cell onClick={handleRowClick}>{flight.upload_status}</Cell>
        <Cell onClick={handleRowClick}>{flight?.farm ?? NO_DATE_VALUE}</Cell>
        <Cell onClick={handleRowClick}>{flight.field_count}</Cell>
        <Cell onClick={handleRowClick}>{flight?.created_by ?? UNKNOWN}</Cell>
        <Cell onClick={handleOpenExternal}>
          <OpenInNewIcon />
        </Cell>
      </Row>
    )
  );
}

FlightRow.propTypes = {
  flightItem: PropTypes.shape({
    id: PropTypes.string,
    created_by: PropTypes.string,
  }),
  filter: PropTypes.string,
  showOnlyFlightsWithoutFields: PropTypes.bool.isRequired,
};

FlightRow.defaultProps = {
  flightItem: null,
  filter: '',
};

export default FlightRow;
