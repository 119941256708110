import React from 'react';
import PropTypes, { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BaseSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConditionalWrap from './ConditionalWrap';
import FormControl from './FormControl';

const useStyles = makeStyles(theme => ({
  control: {
    margin: theme.spacing(2, 0, 0),
  },
}));

function Select({
  name,
  label,
  $value,
  value,
  options,
  inputProps,
  helperText,
  isWrapped,
  fullWidth,
  className,
  disabled,
  useLocalStorage,
  onChange,
  onBlur,
}) {
  const classes = useStyles();

  const handleOnChange = e => {
    if ($value) {
      $value.set(e.target.value);
    }

    if (useLocalStorage) {
      localStorage.setItem(e.target.name, e.target.value);
    }

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const val = $value ? $value.value : value;
  const error = $value ? $value.error : null;

  return (
    <ConditionalWrap
      condition={isWrapped}
      wrap={child => (
        <FormControl fullWidth={fullWidth} className={classes.control}>
          {child}
        </FormControl>
      )}
    >
      <BaseSelect
        name={name}
        label={label}
        disabled={disabled}
        helperText={helperText}
        className={className}
        value={val}
        inputProps={{ ...inputProps, 'data-testid': `input-${name}` }}
        onChange={handleOnChange}
        onBlur={onBlur}
      >
        {options.map(option => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </BaseSelect>
      {error && <span>{error}</span>}
    </ConditionalWrap>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  $value: PropTypes.shape({
    value: PropTypes.string,
    set: func,
    check: func,
    error: string,
  }),
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isWrapped: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  useLocalStorage: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Select.defaultProps = {
  label: '',
  $value: null,
  value: '',
  options: [],
  inputProps: {},
  helperText: '',
  disabled: false,
  isWrapped: false,
  fullWidth: false,
  className: null,
  useLocalStorage: false,
  onChange: null,
  onBlur: null,
};

export default Select;
