import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import getPartialUploads from '../../state/getPartialUploads';
import * as actions from '../../redux/actions';
import { Typography, Table } from '../../../../ui-components';
import UploadTable from './UploadsTable';
import UploadsTableRow from './UploadsTableRow';

const { Row, Cell } = Table;

const completedUploadsColumns = [
  {
    key: 'startDate',
    name: (
      <FormattedMessage id="uploads.startDate" defaultMessage="Start date" />
    ),
  },
  {
    key: 'endDate',
    name: <FormattedMessage id="uploads.endDate" defaultMessage="End date" />,
  },
  {
    key: 'uploadName',
    name: (
      <FormattedMessage id="uploads.uploadName" defaultMessage="Upload name" />
    ),
  },
  {
    key: 'totalFiles',
    name: (
      <FormattedMessage id="uploads.totalFiles" defaultMessage="Total files" />
    ),
  },
];

function UploadsComplete() {
  const [isFetching, setIsFetching] = useState(false);
  const [uploads, setUploads] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      try {
        const response = await getPartialUploads();
        setIsFetching(false);
        setUploads(response.filter(item => item.complete));
      } catch (error) {
        setIsFetching(false);
        actions.showError(error, 'Error fetching completed uploads');
      }
    }
    fetchData();
  }, []);

  const handleRowClick = item => history.push(`/report/${item.id}`);

  return (
    <>
      <Typography variant="h3" component="h2">
        <FormattedMessage
          id="uploads.recentlyCompletedUploads"
          defaultMessage="Recently completed uploads"
        />
      </Typography>

      <UploadTable columns={completedUploadsColumns} isFetching={isFetching}>
        {uploads.length > 0 ? (
          uploads.map(upload => (
            <UploadsTableRow
              key={upload.id}
              item={upload}
              columns={completedUploadsColumns}
              handleRowClick={handleRowClick}
            />
          ))
        ) : (
          <Row>
            <Cell colSpan={completedUploadsColumns.length} align="center">
              <FormattedMessage
                id="uploads.noRecentlyCompletedUploads"
                defaultMessage="No recently completed uploads"
              />
            </Cell>
          </Row>
        )}
      </UploadTable>
    </>
  );
}

export default UploadsComplete;
