import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@hummingbirdtechgroup/wings-auth';
import Uploads from './scenes/Uploads';
import Upload from './scenes/Upload';

function UploadRouter() {
  return (
    <Switch>
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER', 'PILOT']}
        exact
        path="/upload"
        render={() => <Uploads />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER', 'PILOT']}
        exact
        path="/upload/:id"
        render={() => <Upload />}
      />
    </Switch>
  );
}

export default UploadRouter;
