import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import formatISO from 'date-fns/formatISO';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Table, Checkbox } from '../../../../ui-components';
import { YYYY_MM_DD } from '../../../../utils/constants';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';

const { Row, Cell } = Table;

const useStyles = makeStyles(theme => ({
  row: {
    '&.Mui-selected': {
      backgroundColor: '#fff9c4',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#fff9c4',
    },
  },
  cell: {
    padding: theme.spacing(0.5, 1),
  },
}));

function FieldsTableRow({
  columns,
  item,
  activeItem,
  handleRowClick,
  handleFieldDateChange,
}) {
  const classes = useStyles();

  const { dispatch } = useGlobalStore();

  const [includeSurvey, setIncludeSurvey] = useState(true);

  const onRowClick = () => {
    if (typeof handleRowClick === 'function') {
      handleRowClick(item);
    }
  };

  let rowProps = {};
  let rowStyle = {};

  if (handleRowClick) {
    rowProps = {
      hover: true,
      role: 'button',
      tabIndex: -1,
    };

    rowStyle = {
      cursor: 'pointer',
    };
  }

  const isSelected = item.id === activeItem;

  const onExcludeSurvey = () => {
    dispatch({
      type: actions.EXCLUDE_SURVEY,
      payload: {
        fieldId: item.id,
      },
    });
  };

  const onIncludeSurvey = () => {
    dispatch({
      type: actions.INCLUDE_SURVEY,
      payload: {
        fieldId: item.id,
      },
    });
  };

  const onToggleCheckbox = event => {
    const { checked } = event.target;
    setIncludeSurvey(checked);
    if (checked) {
      onIncludeSurvey();
    } else {
      onExcludeSurvey();
    }
  };

  return (
    <Row
      key={item.id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rowProps}
      selected={isSelected}
      style={rowStyle}
      className={classes.row}
      testid={`row-${item.id}`}
    >
      {columns.map(column => {
        if (column.key === 'capturedAt') {
          return (
            <Cell
              key={`${item.id}-${column.key}`}
              className={classes.cell}
              testid={`cell-${item.id}-${column.key}`}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  margin="dense"
                  variant="inline"
                  format={YYYY_MM_DD}
                  value={item.capturedAt}
                  onChange={value =>
                    value && handleFieldDateChange(item.id, formatISO(value))
                  }
                />
              </MuiPickersUtilsProvider>
            </Cell>
          );
        }
        if (column.key === 'include') {
          return (
            <Cell key={`${item.id}-${column.key}`} className={classes.cell}>
              <form onChange={onToggleCheckbox}>
                <Checkbox name="include" checked={includeSurvey} />
              </form>
            </Cell>
          );
        }
        return (
          <Cell
            key={`${item.id}-${column.key}`}
            className={classes.cell}
            testid={`cell-${item.id}-${column.key}`}
            onClick={onRowClick}
          >
            {item[column.key]}
          </Cell>
        );
      })}
    </Row>
  );
}

FieldsTableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.shape({ id: PropTypes.string, capturedAt: PropTypes.string }),
  activeItem: PropTypes.string,
  handleRowClick: PropTypes.func,
  handleFieldDateChange: PropTypes.func,
};

FieldsTableRow.defaultProps = {
  columns: [],
  item: null,
  activeItem: null,
  handleRowClick: null,
  handleFieldDateChange: null,
};

export default FieldsTableRow;
