import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Typography, Progress } from '../../../../ui-components';

const getOnlineColor = (theme, isOnline) => {
  return isOnline ? theme.palette.success.light : theme.palette.error.light;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minHeight: 24,
    marginTop: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  speed: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  connectionIndicator: {
    display: 'inline-block',
    width: 10,
    height: 10,
    backgroundColor: ({ isOnline }) => getOnlineColor(theme, isOnline),
    borderRadius: 20,
    marginRight: theme.spacing(1),
  },
}));

function UploadProgress({
  totalFiles,
  percentageComplete,
  isStarting,
  isStarted,
  isPostUpload,
  isPaused,
  isOnline,
  filesUploadedCount,
  uploadSpeed,
  compression,
}) {
  const classes = useStyles({ isOnline });

  return (
    <div data-testid="progress" className={classes.root}>
      <Progress
        type="linear"
        variant="determinate"
        value={percentageComplete}
      />
      {isPostUpload ? (
        <Typography variant="caption" testid="upload-complete">
          <FormattedMessage
            id="upload.uploadComplete"
            defaultMessage="Upload complete"
          />
        </Typography>
      ) : (
        <>
          {!isStarting && isStarted && (
            <Typography variant="caption" testid="upload-is-in-progress">
              <span className={classes.connectionIndicator} />
              {isOnline ? (
                <FormattedMessage
                  id="upload.uploadingXOfY"
                  defaultMessage="Uploading {filesUploadedCount} of {totalFiles}"
                  values={{ filesUploadedCount, totalFiles }}
                />
              ) : (
                <FormattedMessage
                  id="upload.noConnection"
                  defaultMessage="No connection"
                />
              )}
            </Typography>
          )}
          {!isStarting && !isStarted && (
            <Typography variant="caption" testid="upload-has-progress">
              <span className={classes.connectionIndicator} />
              <FormattedMessage
                id="upload.uploadedXOfY"
                defaultMessage="Uploaded {filesUploadedCount} of {totalFiles}"
                values={{ filesUploadedCount, totalFiles }}
              />
            </Typography>
          )}
          {isStarting && (
            <Typography variant="caption" testid="upload-has-progress">
              <FormattedMessage
                id="upload.uploadStarting"
                defaultMessage="Upload starting"
              />
            </Typography>
          )}
        </>
      )}
      {!isPostUpload && uploadSpeed > 0 && !isPaused && isOnline && (
        <Typography
          variant="caption"
          testid="upload-speed"
          className={classes.speed}
        >
          <FormattedMessage
            id="upload.uploadSpeedAndCompression"
            defaultMessage="{uploadSpeed} kB/s. Compression: {compression}"
            values={{ uploadSpeed: Math.round(uploadSpeed), compression }}
          />
        </Typography>
      )}
      {isPaused && (
        <Typography
          variant="caption"
          testid="upload-paused"
          className={classes.speed}
        >
          <FormattedMessage
            id="upload.uploadPaused"
            defaultMessage="Upload paused"
          />
        </Typography>
      )}
    </div>
  );
}

UploadProgress.propTypes = {
  totalFiles: PropTypes.number,
  percentageComplete: PropTypes.number.isRequired,
  isStarting: PropTypes.bool,
  isStarted: PropTypes.bool,
  isPostUpload: PropTypes.bool,
  isPaused: PropTypes.bool,
  isOnline: PropTypes.bool,
  filesUploadedCount: PropTypes.number,
  uploadSpeed: PropTypes.number,
  compression: PropTypes.number,
};

UploadProgress.defaultProps = {
  totalFiles: 0,
  isStarting: false,
  isStarted: false,
  isPostUpload: false,
  isPaused: false,
  isOnline: true,
  filesUploadedCount: 0,
  uploadSpeed: 0,
  compression: 3,
};

export default UploadProgress;
