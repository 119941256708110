import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Typography } from '../../../ui-components';
import HelpContentNew from './HelpContentNew';
import HelpContentIncomplete from './HelpContentIncomplete';

const { Tab, TabPanel } = Tabs;

const useStyles = makeStyles(theme => ({
  tab: {
    flex: 1,
  },
  panel: {
    minHeight: '70vh',
    paddingTop: theme.spacing(4),
  },
}));

function Help() {
  const [value, setValue] = useState('1');

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography variant="body2" paragraph>
        This help section will provide a step by step guide on how to use this
        tool for creating new uploads and resuming those that are incomplete.
      </Typography>

      <Tabs centered value={value} onChange={handleChange} testid="tabs-help">
        <Tab label="New uploads" value="1" className={classes.tab} />
        <Tab label="Incomplete uploads" value="2" className={classes.tab} />
      </Tabs>

      <TabPanel
        value={value}
        index="1"
        className={classes.panel}
        testid="panel-new-uploads"
      >
        <HelpContentNew />
      </TabPanel>

      <TabPanel
        value={value}
        index="2"
        className={classes.panel}
        testid="panel-incomplete-uploads"
      >
        <HelpContentIncomplete />
      </TabPanel>
    </div>
  );
}

export default Help;
