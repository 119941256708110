import { parseISO, isBefore, subWeeks } from 'date-fns';
import db from '../services/db';
import deletePartialUpload from './deletePartialUpload';

export default async () => {
  const weekAgo = subWeeks(new Date(), 1);
  const items = [];
  let response;

  try {
    response = await db.table('uploads').toArray();
  } catch (error) {
    return Promise.reject(error);
  }

  // Remove items older than 1 week from the db
  response.forEach(item => {
    const endDate = parseISO(item.endDate);

    if (isBefore(endDate, weekAgo)) {
      deletePartialUpload(item.id);
    } else {
      items.push(item);
    }
  });

  return items;
};
