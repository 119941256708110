/*
 * Actions
 * * * * * * * * */

// App actions
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// Flash actions
export const SHOW_FLASH = 'SHOW_FLASH';
export const HIDE_FLASH = 'HIDE_FLASH';

// Settings actions
export const SHOW_SETTINGS = 'SHOW_SETTINGS';
export const HIDE_SETTINGS = 'HIDE_SETTINGS';
export const SET_SETTINGS_VALUE = 'SET_SETTINGS_VALUE';

// Upload actions
export const FETCHING_UPLOAD = 'FETCHING_UPLOAD';
export const FETCHING_UPLOAD_SUCCESS = 'FETCHING_UPLOAD_SUCCESS';
export const FETCHING_UPLOAD_FAIL = 'FETCHING_UPLOAD_FAIL';

export const FILES_SELECTED = 'FILE_SELECTED';
export const FILES_PROCESSED = 'FILES_PROCESSED';
export const FILES_VERIFIED = 'FILES_VERIFIED';
export const VERIFY_ERROR = 'VERIFY_ERROR';

export const SET_SURVEY_DATA = 'SET_SURVEY_DATA';
export const SET_SURVEY_IMAGES_DATA = 'SET_SURVEY_IMAGES_DATA';
export const SET_SURVEY_CAPTURED_TIME = 'SET_SURVEY_CAPTURED_TIME';
export const SET_SURVEY_COVERAGE_DATA = 'SET_SURVEY_COVERAGE_DATA';
export const INCLUDE_SURVEY = 'INCLUDE_SURVEY';
export const EXCLUDE_SURVEY = 'EXCLUDE_SURVEY';

export const SET_TOTAL_FILE_SIZE = 'SET_TOTAL_FILE_SIZE';
export const UPDATE_COMPRESSION_DATA = 'UPDATE_COMPRESSION_DATA';
export const UPDATE_UPLOAD_DATA = 'UPDATE_UPLOAD_DATA';

export const CLEAR_PARTIAL = 'CLEAR_PARTIAL';
export const CLEAR = 'CLEAR';

export const SET_UPLOAD_ERRORS = 'SET_UPLOAD_ERRORS';
export const CLEAR_UPLOAD_ERRORS = 'CLEAR_UPLOAD_ERRORS';

// Upload progress
export const UPLOAD_STARTING = 'UPLOAD_STARTING';
export const UPLOAD_STARTED = 'UPLOAD_STARTED';
export const UPLOAD_PAUSED = 'UPLOAD_PAUSED';
export const UPLOAD_RESUMED = 'UPLOAD_RESUMED';
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';
export const POST_UPLOAD_COMPLETE = 'POST_UPLOAD_COMPLETE';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

// Map
export const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD';
export const SET_MATCHING_BASE_ZONES = 'SET_MATCHING_BASE_ZONES';

/*
 * Action creators
 * * * * * * * * */

export const showError = (error, message = null) => {
  // eslint-disable-next-line no-console
  console.error(message, error);

  return {
    type: SHOW_FLASH,
    payload: {
      status: 'error',
      dismissable: true,
      message: message || error.toString(),
      timeout: null,
    },
  };
};
