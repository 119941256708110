import { useQuery } from 'react-query';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { get } from '@hummingbirdtechgroup/wings-request';

export default function useFlightQuery(flightId, options) {
  const { apiUrl } = useConfig();

  return useQuery(
    ['flight', flightId],
    () =>
      get(apiUrl, `/v1/flights/${flightId}`, {
        include_flight_path: true,
      }),
    options,
  );
}
