import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../App/Layout';
import Upload from '../components/Upload/Upload';
import UploadInfo from '../components/UploadInfo/UploadInfoContainer';
import MapLayout from '../components/Map/MapLayout';
import Settings from '../components/Settings/SettingsContainer';
import { Card } from '../../../ui-components';
import MapContainer from '../components/Map/MapContainer';

const useStyles = makeStyles(theme => ({
  upload: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(6),
      zIndex: 1000,
      width: 400,
    },
  },
}));

function UploadScene() {
  const classes = useStyles();

  return (
    <Layout maxWidth="lg">
      <Card flush className={classes.upload}>
        <Upload />
        <UploadInfo />
      </Card>
      <MapLayout>
        <MapContainer />
      </MapLayout>
      <Settings />
    </Layout>
  );
}

export default UploadScene;
