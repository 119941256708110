/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { arrayOf, node, string, number, object } from 'prop-types';
import BaseBox from '@material-ui/core/Box';

function Box({ children, clone, component, className, spacing }) {
  return (
    <BaseBox
      clone={clone}
      component={component}
      className={className}
      {...spacing}
    >
      {children}
    </BaseBox>
  );
}

Box.propTypes = {
  children: PropTypes.oneOfType([arrayOf(node), node]).isRequired,
  clone: PropTypes.bool,
  component: PropTypes.oneOfType([node, string]),
  className: PropTypes.string,
  spacing: PropTypes.oneOfType([number, object]),
};

Box.defaultProps = {
  clone: false,
  component: 'div',
  className: '',
  spacing: {},
};

export default Box;
