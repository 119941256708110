/* eslint-disable no-await-in-loop */
import { v4 as uuid } from 'uuid';
import { parse } from 'exifr';
import * as Sentry from '@sentry/browser';
import { isImg } from '../../../../utils/image';
import { MAX_NON_IMAGE_FILES_SIZE } from '../../../../utils/constants';
import keysToLowerCase from '../../../../utils/keysToLowerCase';
import * as errorTypes from './errorTypes';
import validateMetaData, { addToErrors } from './validateMetaData';
import addPoint from './addPoint';
import addToManifest from './addToManifest';
import baseManifest from './baseManifest';

export default async (files, onFilesProcessed, onErrors) => {
  const padLength = files.length.toString().length;
  const updateUIIntervalSize = files.length / 15;
  let updateUIIntervalCount = 0;

  let manifest = baseManifest(new Date());

  const nonImgFiles = [];
  let nonImgFileSize = 0;
  let imgFileCount = 0;

  let errors = {};
  let points = {};

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    let metaData = null;

    // Process image files for metadata
    if (isImg(file)) {
      const fileArrayBuffer = await file.arrayBuffer();

      imgFileCount += 1;

      try {
        metaData = await parse(fileArrayBuffer);
        metaData = keysToLowerCase(metaData);

        // Validate metadata and return any errors as an updated errorState object
        errors = validateMetaData(errors, file, metaData);

        // Create a new flight path point and return an updated array
        points = addPoint(points, metaData, uuid());
      } catch (error) {
        errors = addToErrors(errors, errorTypes.PARSE_FAIL, 'parse', [file]);
        Sentry.withScope(scope => {
          scope.setTag('transaction', errorTypes.PARSE_FAIL);
          scope.setTag('upload_size', file.size);
          scope.setTag('total_file_count', files.length);
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureException(error);
        });
      }
    } else {
      nonImgFiles.push(file);
      nonImgFileSize += file.size;
    }

    manifest = await addToManifest(manifest, file, metaData, padLength, i);

    updateUIIntervalCount += 1;

    // Update UI at intervals
    if (updateUIIntervalCount >= updateUIIntervalSize) {
      onFilesProcessed(i + 1);
      onErrors(errors);
      updateUIIntervalCount = 0;
    }
  }

  // Warn if no image files are in directory selected
  if (imgFileCount < 1) {
    errors = addToErrors(errors, errorTypes.NO_IMAGE_FILES, 'validate', []);
  }

  // Warn if the combined non-image file size is greater than the set limit
  if (nonImgFileSize > MAX_NON_IMAGE_FILES_SIZE) {
    errors = addToErrors(
      errors,
      errorTypes.NON_IMAGE_FILE_SIZE,
      'validate',
      nonImgFiles,
    );
  }

  // All files processed UI update
  onFilesProcessed(files.length);
  onErrors(errors);

  // Sort by datetime
  Object.keys(points).forEach(type => {
    points[type].sort((a, b) => {
      return a.datetime < b.datetime ? 1 : -1;
    });
  });

  return { points, manifest };
};
