import React, { useEffect, useState } from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { getLocaleData } from '../utils/localStorage';

import en_UK from '../i18n/en-UK.json';

const DEFAULT_LANGUAGE = 'en-UK';

const LocaleProvider = ({ children }) => {
  const locale = getLocaleData();
  const [translations, setMessages] = useState(en_UK);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const nextMessages = await import(
          /* webpackChunkName: "translation" */ `../i18n/${locale}.json`
        );

        setMessages(nextMessages.default);
      } catch (error) {
        console.error('Dynamic lang translations load failure: ', error);
      }
      return null;
    }

    if (locale !== DEFAULT_LANGUAGE) {
      fetchMessages();
    } else {
      setMessages(en_UK);
    }
  }, [locale]);
  return (
    <IntlProvider locale={locale} messages={{ ...en_UK, ...translations }}>
      {children}
    </IntlProvider>
  );
};

LocaleProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default LocaleProvider;
