import { area } from '@turf/turf';

function findPercentAreaAisB(geometryA, geometryB) {
  if (!geometryA || !geometryB) return 0;

  const geometryAreaA = area(geometryA);
  const geometryAreaB = area(geometryB);

  if (geometryAreaA < geometryAreaB) {
    const diff = geometryAreaB - geometryAreaA;
    return 100 - diff / (geometryAreaB / 100);
  }
  return 100;
}

export default findPercentAreaAisB;
