/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import { getToken } from '@hummingbirdtechgroup/wings-auth';
import {
  getLS,
  getLocalStorageObject,
  setLocalStorageObject,
} from './localStorage';
import { SLACK_TS_KEY } from './constants';
import config from '../config';
import byteToText from './formatByteText';

// uploader-status channelId
const UPLOADER_TEST_CHANNEL_ID = 'C01S4HK6B4P';
const UPLOADER_STATUS_CHANNEL_ID = 'C01RFEKBWHJ';
const SLACK_CHANNEL =
  process.env.API_ENV === 'production'
    ? UPLOADER_STATUS_CHANNEL_ID
    : UPLOADER_TEST_CHANNEL_ID;

function storeUploadNotificationTs(uploadId, slackTs) {
  const notifications = getLocalStorageObject(SLACK_TS_KEY);

  setLocalStorageObject(SLACK_TS_KEY, {
    [uploadId]: slackTs,
    ...notifications,
  });
}

function removeUploadNotificationTs(uploadId) {
  const notifications = getLocalStorageObject(SLACK_TS_KEY);

  if (notifications?.[uploadId]) {
    delete notifications[uploadId];
  }

  setLocalStorageObject(SLACK_TS_KEY, notifications);
}

function getUploadNotificationTs(uploadId) {
  return getLocalStorageObject(SLACK_TS_KEY)[uploadId];
}

/**
 * If a key is deleted for some reason,
 * this restarts the thread from the last message
 *
 * @param {string} ts - ts from a slack message response
 */
function resetTs(uploadId, ts) {
  const storedTS = getUploadNotificationTs(uploadId);
  if (!storedTS) {
    storeUploadNotificationTs(uploadId, ts);
  }
}

async function notifySlack(message) {
  try {
    const res = await fetch(`${config.common.adminSlackBotUrl}/post-message`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        channel: SLACK_CHANNEL,
        ...message,
      }),
    });

    const body = await res.json();
    return body.ts;
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('transaction', 'SLACK_NOTIFICATION');
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(error);
    });
    return error;
  }
}

export async function notifySlackUploadInit(
  adminUrl,
  uploadId,
  totalFiles,
  uploadSize,
) {
  const user = JSON.parse(getLS('user'));
  try {
    const ts = await notifySlack({
      attachments: [
        {
          color: '#EDE275',
          blocks: [
            {
              type: 'header',
              text: {
                type: 'plain_text',
                text: `New upload started by ${user.name}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `ID: *${uploadId}* `,
              },
              accessory: {
                type: 'button',
                text: {
                  type: 'plain_text',
                  text: 'Open in Admin',
                  emoji: true,
                },
                url: `${adminUrl}uploads/${uploadId}`,
                action_id: 'button-action',
              },
            },
            {
              type: 'divider',
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Pilot Contact*: ${user.email}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Expected Files*: ${totalFiles}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Expected Upload Size*: ${byteToText(uploadSize)}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Environment*: ${process.env.API_ENV}`,
              },
            },
            {
              type: 'divider',
            },
          ],
        },
      ],
    });

    // Notifications for the same upload are threaded into the same message with this ts key
    storeUploadNotificationTs(uploadId, ts);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadPause(
  uploadId,
  uploadedFiles,
  totalFiles,
) {
  const ts = getUploadNotificationTs(uploadId);
  try {
    const subTs = await notifySlack({
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `:double_vertical_bar: Paused at ${uploadedFiles}/${totalFiles} files`,
          },
        },
      ],
    });

    resetTs(uploadId, subTs);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadResume(uploadId) {
  const ts = getUploadNotificationTs(uploadId);
  try {
    const subTs = await notifySlack({
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: ':arrow_forward: Upload resumed',
          },
        },
      ],
    });
    resetTs(uploadId, subTs);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadCompleted(
  uploadId,
  size,
  totalFiles,
  fieldlessUpload,
) {
  const ts = getUploadNotificationTs(uploadId);
  try {
    await notifySlack({
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `:white_check_mark: ${totalFiles} files uploaded successfully ${
              fieldlessUpload ? 'Upload has no fields selected' : ''
            }`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Size: ${byteToText(size)}`,
          },
        },
      ],
    });
    // clean up local storage after upload is complete
    removeUploadNotificationTs(uploadId);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadProgress(
  uploadId,
  uploadedFiles,
  totalFiles,
) {
  const ts = getUploadNotificationTs(uploadId);
  if (ts !== undefined) {
    try {
      const subTs = await notifySlack({
        thread_ts: ts,
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `:arrows_counterclockwise: ${uploadedFiles} of ${totalFiles} uploaded`,
            },
          },
        ],
      });
      resetTs(uploadId, subTs);
    } catch (error) {
      console.error('SLACK NOTIFY ERROR', error);
    }
  }
}

export async function notifySlackUploadFileFailure(
  uploadId,
  fileName,
  fileType,
  fileSize,
) {
  const ts = getUploadNotificationTs(uploadId);
  try {
    const subTs = await notifySlack({
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `:put_litter_in_its_place: Failure Uploading: ${fileName}.  Discarding image.`,
          },
        },
        fileSize && {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Size: ${byteToText(fileSize)}`,
          },
        },
        fileType && {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `File Type: ${fileType}`,
          },
        },
      ].filter(Boolean),
    });
    resetTs(uploadId, subTs);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadError(uploadId, message) {
  const ts = getUploadNotificationTs(uploadId);
  try {
    const subTs = await notifySlack({
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `:sos: Error: ${message}`,
          },
        },
      ],
    });
    resetTs(uploadId, subTs);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}

export async function notifySlackUploadInfo(uploadId, message) {
  const slackMessage = {
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:information_source: ${message}`,
        },
      },
    ],
  };

  if (uploadId) {
    slackMessage.thread_ts = getUploadNotificationTs(uploadId);
  }

  try {
    const subTs = await notifySlack(slackMessage);
    resetTs(uploadId, subTs);
  } catch (error) {
    console.error('SLACK NOTIFY ERROR', error);
  }
}
