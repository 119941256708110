import L from 'leaflet';

export default (flightPathPoints, metaData, key) => {
  const points = flightPathPoints;

  // Create flight path points
  const {
    datetime,
    datetimeoriginal,
    longitude,
    latitude,
    make,
    model,
    serialnumber,
    software,
  } = metaData;

  let cameraKey;

  if (make && model) {
    cameraKey = `${make} ${model}`;
  } else if (serialnumber && software) {
    cameraKey = `${serialnumber} ${software}`;
  } else {
    cameraKey = 'Unknown';
  }

  points[cameraKey] = points[cameraKey] || [];

  const date = datetime || datetimeoriginal;

  if (!date || !longitude || !latitude) return flightPathPoints;

  points[cameraKey].push({
    key,
    datetime: date,
    point: L.point([latitude, longitude]),
  });

  return points;
};
