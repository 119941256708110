import React, { useEffect, useState, useCallback } from 'react';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useLink } from 'valuelink';
import { get, round } from 'lodash';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/browser';
import formatISO from 'date-fns/formatISO';
import fileFilter from '../../../../utils/fileFilter';
import Uploader from '../../services/upload/Uploader';
import getUploadObjectId from '../../state/getUploadObjectId';
import getUploadToken from '../../state/getUploadToken';
import patchFlightStatusCompleted from '../../state/patchFlightStatusCompleted';
import putPartialUpload from '../../state/putPartialUpload';
import {
  updatePartialUploadCount,
  updatePartialUploadAsCompleted,
} from '../../state/updatePartialUpload';
import checkHasProcessingErrors from '../../services/fileProcessing/checkHasProcessingErrors';
import processFiles from '../../services/fileProcessing/processFiles';
import verifyFiles from '../../services/fileProcessing/verifyFiles';
import getPreprocessedManifestImages from '../../services/fileProcessing/getPreprocessedImages';
import * as actions from '../../redux/actions';
import { useGlobalStore } from '../../../../App/redux/GlobalStore';
import UploadForm from './UploadForm';
import useLastLogin from '../../../../utils/useLastLogin';
import {
  notifySlackUploadInit,
  notifySlackUploadProgress,
  notifySlackUploadPause,
  notifySlackUploadResume,
  notifySlackUploadCompleted,
  notifySlackUploadError,
  notifySlackUploadInfo,
} from '../../../../utils/notifySlack';
import recoverUploadObjectId from '../../state/recoverUploadObjectId';
import extractPoints from '../../services/fileProcessing/geometryUtils/extractPoints';

const UPLOAD_NOTIFY_THRESHOLD_PERCENTAGE = 0.25;
const USER_KEY = 'user';

let progressNotifyThreshold = 20;
let files = null;
let uploaderInstance = null;
let uploadId = null;
let authToken = null;
let surveyIds = [];

// refreshTokenTries is incremented when fetching a refreshed token and reset
// to 0 when any file is uploaded successfully. This should stop an infinite loop if
// returned token continuously fails for any reason
let refreshTokenTries = 0;

function Upload() {
  const lastLogin = useLastLogin();
  const config = useConfig();

  const intl = useIntl();
  const {
    dispatch,
    settings: { values: settings },
    upload: {
      isFilesSelected,
      isProcessingComplete,
      manifest,
      progress,
      errors,
      totalFiles,
      filesUploadedCount,
      partialUpload,
      verifyError,
      surveyData,
      surveyImagesData,
      coverageData,
    },
    progress: { isStarting, isStarted, isInProgress, isPaused, isPostUpload },
    map: { points: mapPoints },
  } = useGlobalStore();
  const $override = useLink(false);
  const [isOnline, setIsOnline] = useState(true);
  const [filesProcessedCount, setFilesProcessedCount] = useState(0);
  const [currentCompression, setCurrentCompression] = useState(
    settings.compression,
  );

  const isComplete = filesUploadedCount > 0 && filesUploadedCount >= totalFiles;
  const isAllFieldsExcluded = Boolean(
    surveyData &&
      !Object.keys(surveyData).filter(key => surveyData[key].include).length,
  );

  const fieldlessUpload = () => !surveyData || !Object.keys(surveyData).length;

  const pause = useCallback(() => {
    if (uploaderInstance) {
      uploaderInstance.pause();
      dispatch({ type: actions.UPLOAD_PAUSED });
    }
  }, [dispatch]);

  // Add/remove event handler to warn against navigating away from page while uploading
  useEffect(() => {
    if (isStarted) {
      window.onbeforeunload = e => {
        e.preventDefault();
        // Message not customisable in all browser. Adding anyway
        return intl.formatMessage({
          id: 'upload.anUploadIsInProgress',
          defaultMessage:
            'An upload is in progress. Leaving this page will pause it. Continue?',
        });
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isStarted]);

  // Progress updates every 20 successful uploads
  useEffect(() => {
    if (!progressNotifyThreshold) {
      progressNotifyThreshold = round(
        totalFiles * UPLOAD_NOTIFY_THRESHOLD_PERCENTAGE,
        0,
      );
    }
    if (
      filesUploadedCount > 0 &&
      (filesUploadedCount % progressNotifyThreshold === 0 ||
        filesUploadedCount === totalFiles)
    ) {
      notifySlackUploadProgress(uploadId, filesUploadedCount, totalFiles);
    }
  }, [totalFiles, filesUploadedCount]);

  // Pause the upload on unmount
  useEffect(() => {
    return () => {
      pause();
    };
  }, [pause]);

  // Fetch new token and restart upload
  const refreshToken = async () => {
    try {
      const response = await getUploadToken(config.apiUrl);
      authToken = get(response, 'token', null);
      uploaderInstance.resume(authToken);
    } catch (error) {
      Sentry.captureException(
        new Error(`Token Refresh Error.  Last login: ${lastLogin}`),
      );
      dispatch({ type: actions.UPLOAD_ERROR });
      dispatch(
        actions.showError(
          error,
          intl.formatMessage({
            id: 'upload.thereWasAProblemRefreshingTheToken',
            defaultMessage: 'There was a problem refreshing the token',
          }),
        ),
      );
    }
  };

  // Uploader instance callback functions

  const onOnline = () => {
    setIsOnline(true);
  };

  const onOffline = () => {
    setIsOnline(false);
  };

  const onUploadStarted = () => {
    dispatch({ type: actions.UPLOAD_STARTED });
  };

  const onFilesProcessed = num => {
    setFilesProcessedCount(num);
  };

  const onFileCompressed = e => {
    const timeCompleteInSeconds = e.timeComplete / 1000;
    const data = {
      x: timeCompleteInSeconds,
      y: e.compressionSpeed,
    };
    dispatch({
      type: actions.UPDATE_COMPRESSION_DATA,
      payload: data,
    });
  };

  const onFileUploaded = e => {
    const timeCompleteInSeconds = e.timeComplete / 1000;
    const data = {
      x: timeCompleteInSeconds,
      y: e.averageUploadSpeed,
    };

    refreshTokenTries = 0;

    try {
      updatePartialUploadCount(uploadId);
    } catch (error) {
      dispatch(
        actions.showError(
          error,
          intl.formatMessage({
            id: 'upload.errorUpdatingUploadCount',
            defaultMessage: 'Error updating upload count in db',
          }),
        ),
      );
    }

    dispatch({
      type: actions.UPDATE_UPLOAD_DATA,
      payload: {
        uploadData: data,
        uploadSpeed: e.averageUploadSpeed,
      },
    });
  };

  const onUploadComplete = async e => {
    const size = e.bytesUploaded;

    $override.set(false);

    let isError = false;

    // set flight status to completed

    dispatch({
      type: actions.UPLOAD_COMPLETE,
      payload: size,
    });
    notifySlackUploadInfo(uploadId, 'Post upload starting');

    try {
      await patchFlightStatusCompleted(config.apiUrl, uploadId);
      notifySlackUploadInfo(uploadId, 'Flight PATCH upload_status: completed');
    } catch (error) {
      isError = true;
      dispatch(
        actions.showError(error, 'Error patching flight status completed'),
      );
      notifySlackUploadError(uploadId, 'Flight PATCH upload_status: completed');
    }

    try {
      await updatePartialUploadAsCompleted(uploadId);
      notifySlackUploadInfo(uploadId, 'Partial Upload marked as completed');
    } catch (error) {
      isError = true;
      dispatch(
        actions.showError(error, 'Error updating partial upload as completed'),
      );
      notifySlackUploadError(uploadId, 'Partial Upload marked as completed');
    }

    if (surveyIds.length > 0) {
      notifySlackUploadInfo(
        uploadId,
        `Orthomosaic POST for surveys: ${surveyIds.toString()}`,
      );
    }

    if (!isError) {
      dispatch({
        type: actions.POST_UPLOAD_COMPLETE,
        payload: size,
      });
      notifySlackUploadCompleted(uploadId, size, totalFiles, fieldlessUpload());
    }
  };

  const onUploadError = error => {
    if (refreshTokenTries < 10) {
      refreshTokenTries += 1;
      refreshToken();
      return;
    }

    dispatch({ type: actions.UPLOAD_ERROR });
    dispatch(
      actions.showError(
        error,
        intl.formatMessage({
          id: 'upload.maximumNumberOfRetriesExceeded',
          defaultMessage:
            'There was a problem with the upload. Maximum number of retries exceeded.',
        }),
      ),
    );
  };

  const onCompressionChange = level => {
    setCurrentCompression(level);
  };

  const onFilesProcessedErrors = err => {
    dispatch({
      type: actions.SET_UPLOAD_ERRORS,
      payload: err,
    });
  };

  const createUploaderInstance = () => {
    uploaderInstance = new Uploader({
      files,
      manifest,
      uploadId,
      progress,
      bucket: settings.bucket,
      numberOfConnections: settings.connections,
      compression: parseInt(settings.compression, 10),
      fineTuningEnabled: settings.fineTuningEnabled,
      compressionEnabled: settings.compressionEnabled,
      onUploadStarted,
      onFileCompressed,
      onFileUploaded,
      onUploadComplete,
      onUploadError,
      onCompressionChange,
      onOnline,
      onOffline,
      lastLogin,
    });
  };

  // Component handlers

  const processNewFiles = async filteredFiles => {
    let { path } = filteredFiles[0];

    // Dropped files include '/' at the start
    // while those selected via dialog do not
    if (path.charAt(0) === '/') {
      path = path.substr(1);
    }

    dispatch({
      type: actions.FILES_SELECTED,
      payload: filteredFiles.length + 1,
    });

    dispatch({
      type: actions.SET_SETTINGS_VALUE,
      payload: {
        name: 'uploadName',
        value: path.split('/')[0],
      },
    });

    const { points, manifest: fileManifest } = await processFiles(
      filteredFiles,
      onFilesProcessed,
      onFilesProcessedErrors,
    );

    dispatch({
      type: actions.FILES_PROCESSED,
      payload: {
        points,
        manifest: fileManifest,
      },
    });

    const surveyImagesDataPayload = getPreprocessedManifestImages(
      fileManifest,
      extractPoints(points),
    );

    dispatch({
      type: actions.SET_SURVEY_IMAGES_DATA,
      payload: surveyImagesDataPayload,
    });
  };

  const validateFilesForExistingUpload = async filteredFiles => {
    try {
      await verifyFiles(filteredFiles, manifest);
      const manifestCount = 1;

      dispatch({
        type: actions.FILES_VERIFIED,
        payload: filteredFiles.length + manifestCount,
      });
      onFilesProcessed(filteredFiles.length);
    } catch (error) {
      dispatch({
        type: actions.VERIFY_ERROR,
        payload: error.message,
      });
    }
  };

  const handleFilesAdded = acceptedFiles => {
    const filteredFiles = fileFilter(acceptedFiles);

    if (filteredFiles.length < 1) {
      // eslint-disable-next-line no-console
      console.warn(
        intl.formatMessage({
          id: 'upload.noEligibleFilesInSelection',
          defaultMessage: 'No eligible files in selection. Images must be jpg.',
        }),
      );
    }

    // Set files for use by submit and uploader
    files = filteredFiles;

    // If it's a partial upload and a manifest is found, verify
    // otherwise process the upload from the start
    if (partialUpload && manifest) {
      validateFilesForExistingUpload(filteredFiles);
    } else {
      processNewFiles(filteredFiles);
    }
  };

  const convertToMultiPoint = (coordinates = []) => ({
    type: 'MultiPoint',
    coordinates,
  });

  const buildFlightPayload = fileSize => {
    const surveys =
      surveyData && Object.keys(surveyData).length > 0
        ? Object.keys(surveyData)
            .filter(fieldId => surveyData[fieldId].include)
            .map(fieldId => {
              return {
                ...surveyData[fieldId].surveyBaseData,
                preprocessed_images: surveyImagesData.map(data => ({
                  ...data,
                  flight_path: convertToMultiPoint(
                    coverageData[fieldId].flightPath,
                  ),
                  percentage_covered: coverageData[fieldId].percent || -1,
                })),
              };
            })
        : [];
    return {
      flight: {
        // "created_by": "1234",
        filename: settings.uploadName,
        flown_at: formatISO(
          manifest?.files?.[Object.keys(manifest.files)[0]]?.metadata
            ?.datetime ?? new Date(),
        ),
        file_size: fileSize,
        flight_path: {
          type: 'MultiPoint',
          coordinates: extractPoints(mapPoints),
        },
        surveys,
      },
    };
  };

  const handleUploadStart = async () => {
    if (files.length < 1) return;

    const sizeOfSelectedFiles = files.reduce(
      (size, file) => size + file.size,
      0,
    );

    dispatch({
      type: actions.SET_TOTAL_FILE_SIZE,
      payload: sizeOfSelectedFiles,
    });

    let flightPayload;
    let tokenResponse;
    let response;

    try {
      flightPayload = buildFlightPayload(sizeOfSelectedFiles);
      notifySlackUploadInfo(uploadId, 'Flight Payload Built');
    } catch (error) {
      Sentry.captureException(
        new Error(`Error building flight payload.  Last login: ${lastLogin}`),
      );
      notifySlackUploadError(uploadId, 'Building Flight Payload');
      dispatch(actions.showError(error, 'Error building flight payload'));
    }

    try {
      tokenResponse = await getUploadToken(config.apiUrl);
      notifySlackUploadInfo(uploadId, 'Auth Token Retrieved');
    } catch (error) {
      Sentry.captureException(
        new Error(`Unable to GET auth token.  Last login: ${lastLogin}`),
      );
      notifySlackUploadError(uploadId, 'Unable to GET auth token');
      dispatch(actions.showError(error, 'Unable to GET auth token'));
    }

    if (partialUpload) {
      try {
        response = await recoverUploadObjectId(partialUpload, settings);
        notifySlackUploadInfo(uploadId, 'Recovered Upload Object Id');
      } catch (error) {
        Sentry.captureException(
          new Error(
            `Error recovering upload object id.  Last login: ${lastLogin}`,
          ),
        );
        notifySlackUploadError(uploadId, 'Unable to recover upload oject id');
        dispatch(actions.showError(error, 'Error recovering upload object id'));
      }
    } else {
      try {
        response = await getUploadObjectId(config.apiUrl, flightPayload);
        notifySlackUploadInfo(uploadId, 'GET new upload object id');
      } catch (error) {
        Sentry.captureException(
          new Error(`Error Registering Upload.  Last login: ${lastLogin}`),
        );
        notifySlackUploadError(uploadId, 'Unable to GET new upload object id');
        dispatch(
          actions.showError(
            error,
            intl.formatMessage({
              id: 'upload.errorRegisteringUpload',
              defaultMessage: 'Error registering upload',
            }),
          ),
        );
      }
    }

    uploadId = get(response, 'flight.id', null);
    surveyIds = get(response, 'flight.survey_ids', []);
    authToken = get(tokenResponse, 'token', null);

    notifySlackUploadInit(
      config.adminUrl,
      uploadId,
      totalFiles,
      sizeOfSelectedFiles,
    );

    dispatch({
      type: actions.SET_SETTINGS_VALUE,
      payload: {
        name: 'uploadId',
        value: uploadId,
      },
    });

    if (!partialUpload) {
      try {
        putPartialUpload({
          id: uploadId,
          authToken,
          uploadName: settings.uploadName,
          filesUploadedCount: 0,
          totalFiles,
        });
      } catch (error) {
        Sentry.captureException(
          new Error(
            `Error Adding Upload.  Last login: ${get(
              JSON.parse(sessionStorage.getItem(USER_KEY)),
              'last_login',
              '',
            )}`,
          ),
        );
        dispatch(
          actions.showError(
            error,
            intl.formatMessage({
              id: 'upload.errorAddingUpload',
              defaultMessage: 'Error adding upload to db',
            }),
          ),
        );
      }
    }

    await createUploaderInstance();

    if (partialUpload) {
      uploaderInstance.start(partialUpload.token);
    } else {
      uploaderInstance.start(settings.authToken || authToken);
    }

    dispatch({ type: actions.UPLOAD_STARTING });
  };

  const handlePause = () => {
    pause();
    notifySlackUploadPause(uploadId, filesUploadedCount, totalFiles);
  };

  const handleResume = () => {
    refreshTokenTries = 0;
    uploaderInstance.resume(settings.authToken || authToken);
    dispatch({ type: actions.UPLOAD_RESUMED });
    notifySlackUploadResume(uploadId);
  };

  const handleClear = () => {
    $override.set(false);
    if (partialUpload) {
      dispatch({ type: actions.CLEAR_PARTIAL });
    } else {
      dispatch({ type: actions.CLEAR });
    }
  };

  return (
    <UploadForm
      totalFiles={totalFiles}
      compression={parseInt(currentCompression, 10)}
      isFilesSelected={isFilesSelected}
      isProcessingComplete={isProcessingComplete}
      isUploadReady={surveyData && !!Object.keys(surveyData).length}
      isSurveyDataNotAvailable={fieldlessUpload()}
      isStarting={isStarting}
      isStarted={isStarted}
      isInProgress={isInProgress}
      isPostUpload={isPostUpload}
      isPaused={isPaused}
      isComplete={isComplete}
      isOnline={isOnline}
      isAllFieldsExcluded={isAllFieldsExcluded}
      hasProcessingErrors={checkHasProcessingErrors(errors)}
      verifyError={verifyError}
      filesProcessedCount={filesProcessedCount}
      $override={$override}
      partialUpload={partialUpload}
      handleFilesAdded={handleFilesAdded}
      handleUploadStart={handleUploadStart}
      handlePause={handlePause}
      handleResume={handleResume}
      handleClear={handleClear}
    />
  );
}

export default Upload;
