import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useLinkType } from '../../../../types';
import { Button, ButtonsSpacer, Dialog } from '../../../../ui-components';
import Message from './Message';
import UploadDropzone from './UploadDropzone';
import UploadProgressContainer from './UploadProgressContainer';

const { DialogContentText, DialogActions } = Dialog;

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2),
  },
  buttons: {
    padding: theme.spacing(2, 0, 0),
  },
}));

function UploadForm({
  totalFiles,
  compression,
  isFilesSelected,
  isProcessingComplete,
  isUploadReady,
  isSurveyDataNotAvailable,
  isStarting,
  isStarted,
  isInProgress,
  isPostUpload,
  isComplete,
  isPaused,
  isOnline,
  isAllFieldsExcluded,
  hasProcessingErrors,
  verifyError,
  filesProcessedCount,
  $override,
  partialUpload,
  handleFilesAdded,
  handleUploadStart,
  handlePause,
  handleResume,
  handleClear,
}) {
  const classes = useStyles();

  const intl = useIntl();

  const [showNoFieldsDialog, setShowNoFieldsDialog] = useState(false);

  const showUpload = !isInProgress;
  const showPause = isStarted && !isPaused && !isComplete;
  const showResume = isInProgress && isPaused;

  const showOverride = () => {
    if (isInProgress || isStarting || isComplete) return false;
    return isProcessingComplete && hasProcessingErrors;
  };

  const showFileSelectMessage = partialUpload && !isFilesSelected;

  const isUploadDisabled = () => {
    if (verifyError) return true;
    if ($override.value && isStarting) return true;
    if ($override.value && !isStarting) return false;

    return (
      !isProcessingComplete ||
      isStarting ||
      isComplete ||
      hasProcessingErrors ||
      !isUploadReady
    );
  };

  const isResumeDisabled =
    isStarting || !isFilesSelected || !isProcessingComplete;

  const isClearEnabled = () =>
    (isFilesSelected && isProcessingComplete && !isStarted) || isPostUpload;

  const handleSubmit = e => {
    e.preventDefault();
    if (isAllFieldsExcluded) {
      setShowNoFieldsDialog(true);
    } else {
      handleUploadStart();
    }
  };

  const handleHideNoFieldsDialog = () => setShowNoFieldsDialog(false);

  return (
    <>
      <Prompt
        when={isStarted}
        message={intl.formatMessage({
          id: 'upload.anUploadIsInProgress',
          defaultMessage:
            'An upload is in progress. Leaving this page will pause it. Continue?',
        })}
      />
      <form
        data-testid="uploadform"
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <UploadDropzone
          isFilesSelected={isFilesSelected}
          filesProcessedCount={filesProcessedCount}
          totalFiles={totalFiles}
          verifyError={verifyError}
          isComplete={isComplete}
          handleFilesAdded={handleFilesAdded}
        />
        <ButtonsSpacer variant="right" className={classes.buttons}>
          {showUpload && (
            <Button
              type="submit"
              color="primary"
              testid="button-upload"
              disabled={isUploadDisabled()}
            >
              <FormattedMessage
                id="upload.uploadFiles"
                defaultMessage="Upload files"
              />
            </Button>
          )}

          {showPause && (
            <Button
              type="button"
              color="primary"
              testid="button-pause"
              onClick={handlePause}
              disabled={!isOnline}
            >
              <FormattedMessage
                id="upload.pauseUpload"
                defaultMessage="Pause upload"
              />
            </Button>
          )}

          {showResume && (
            <Button
              type="button"
              color="primary"
              testid="button-resume"
              disabled={isResumeDisabled}
              onClick={handleResume}
            >
              <FormattedMessage
                id="upload.resumeUpload"
                defaultMessage="Resume Upload"
              />
            </Button>
          )}

          <Button
            type="button"
            color="secondary"
            testid="button-clear"
            disabled={!isClearEnabled()}
            onClick={handleClear}
          >
            <FormattedMessage id="upload.clear" defaultMessage="Clear" />
          </Button>
        </ButtonsSpacer>
        <UploadProgressContainer
          isOnline={isOnline}
          compression={compression}
        />
      </form>

      <Dialog
        title={
          <FormattedMessage
            id="uploads.noFieldsSelected"
            defaultMessage="No fields selected"
          />
        }
        open={showNoFieldsDialog}
        onClose={handleHideNoFieldsDialog}
      >
        <DialogContentText>
          <FormattedMessage
            id="uploads.doYouWishToContinue"
            defaultMessage="Do you wish to continue"
          />
        </DialogContentText>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={handleHideNoFieldsDialog}
          >
            <FormattedMessage id="uploads.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            autoFocus
            variant="text"
            color="primary"
            onClick={() => {
              handleHideNoFieldsDialog();
              handleUploadStart();
            }}
          >
            <FormattedMessage id="uploads.confirm" defaultMessage="Confirm" />
          </Button>
        </DialogActions>
      </Dialog>

      {showOverride() && (
        <Message
          title={intl.formatMessage({
            id: 'upload.weFoundProblemsWithSomeFiles',
            defaultMessage: 'We found problems with some files',
          })}
          message={intl.formatMessage({
            id: 'upload.pleaseCheckTheErrorsBelowAndConfirm',
            defaultMessage:
              'Please check the errors below and confirm if you wish to proceed with the upload.',
          })}
          status="warning"
          messageTestId="override-message"
          checkboxTestId="checkbox-override"
          $value={$override}
        />
      )}

      {isProcessingComplete && isSurveyDataNotAvailable && !showOverride() && (
        <Message
          title={intl.formatMessage({
            id: 'upload.noAvailableFields',
            defaultMessage: 'There are no available fields',
          })}
          message={intl.formatMessage({
            id: 'upload.confirmNoFields',
            defaultMessage:
              'Confirm if you would like to proceed with the upload. No surveys will be created.',
          })}
          status="warning"
          messageTestId="override-message"
          checkboxTestId="checkbox-confirm-no-fields"
          $value={$override}
        />
      )}

      {showFileSelectMessage && isFilesSelected && verifyError && (
        <Message
          title={intl.formatMessage({
            id: 'upload.fileSelectionNeeded',
            defaultMessage: 'File selection needed',
          })}
          message={intl.formatMessage({
            id: 'upload.pleaseReselectTheOriginalFileFolder',
            defaultMessage:
              'Please reselect the original file folder used for this upload above',
          })}
          status="warning"
          messageTestId="reselect-message"
          $value={$override}
        />
      )}

      {verifyError && (
        <Message
          title={intl.formatMessage({
            id: 'upload.selectedFilesMismatch',
            defaultMessage: 'Selected files mismatch',
          })}
          message={verifyError}
          status="error"
          messageTestId="verify-message"
        />
      )}
    </>
  );
}

UploadForm.propTypes = {
  totalFiles: PropTypes.number,
  compression: PropTypes.number,
  isFilesSelected: PropTypes.bool,
  isProcessingComplete: PropTypes.bool,
  isUploadReady: PropTypes.bool,
  isSurveyDataNotAvailable: PropTypes.bool,
  isStarting: PropTypes.bool,
  isStarted: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isPostUpload: PropTypes.bool,
  isComplete: PropTypes.bool,
  isPaused: PropTypes.bool,
  isOnline: PropTypes.bool,
  isAllFieldsExcluded: PropTypes.bool,
  hasProcessingErrors: PropTypes.bool,
  verifyError: PropTypes.string,
  filesProcessedCount: PropTypes.number,
  $override: useLinkType.isRequired,
  partialUpload: PropTypes.shape({}),
  handleFilesAdded: PropTypes.func.isRequired,
  handleUploadStart: PropTypes.func.isRequired,
  handlePause: PropTypes.func.isRequired,
  handleResume: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};

UploadForm.defaultProps = {
  totalFiles: 0,
  compression: 3,
  isFilesSelected: false,
  isProcessingComplete: false,
  isUploadReady: false,
  isSurveyDataNotAvailable: true,
  isStarting: false,
  isStarted: false,
  isInProgress: false,
  isPostUpload: false,
  isComplete: false,
  isPaused: false,
  isOnline: true,
  isAllFieldsExcluded: false,
  hasProcessingErrors: false,
  verifyError: null,
  filesProcessedCount: 0,
  partialUpload: null,
};

export default UploadForm;
