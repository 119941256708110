import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Table } from '../../../ui-components';
import UploadSurveysRow from './UploadSurveysRow';

const useStyles = makeStyles(theme => ({
  wrap: {
    margin: theme.spacing(2, 0, 4, 0),
  },
}));

const { Body, Container, Head, Row, Cell } = Table;
function UploadSurveysList({ surveyIdList }) {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Container>
        <Table>
          <Head>
            <Row>
              <Cell>
                <FormattedMessage
                  id="upload.surveyID"
                  defaultMessage="Survey ID"
                />
              </Cell>
              <Cell>
                <FormattedMessage id="upload.farm" defaultMessage="Farm" />
              </Cell>
              <Cell>
                <FormattedMessage id="upload.field" defaultMessage="Field" />
              </Cell>
              <Cell>
                <FormattedMessage
                  id="upload.list.orthoType"
                  defaultMessage="Image Type"
                />
              </Cell>
              <Cell>
                <FormattedMessage
                  id="upload.list.imageCount"
                  defaultMessage="Total Images"
                />
              </Cell>
              <Cell>
                <FormattedMessage
                  id="upload.list.orthoId"
                  defaultMessage="Report Download"
                />
              </Cell>
              <Cell>
                <FormattedMessage
                  id="upload.inPlatform"
                  defaultMessage="In platform"
                />
              </Cell>
            </Row>
          </Head>
          <Body>
            {surveyIdList.length > 0 ? (
              surveyIdList.map(surveyId => (
                <UploadSurveysRow surveyId={surveyId} />
              ))
            ) : (
              <Row>
                <Cell align="center">
                  <FormattedMessage
                    id="upload.noAvailableSurveys"
                    defaultMessage="No available surveys."
                  />
                </Cell>
              </Row>
            )}
          </Body>
        </Table>
      </Container>
    </Box>
  );
}

UploadSurveysList.propTypes = {
  surveyIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UploadSurveysList;
