import get from 'lodash/get';
import config from '../../../../config';

export default datetime => {
  return {
    version: get(config, 'common.appVersion'),
    'upload-datetime': datetime.toISOString(),
    compression: {
      type: 'gzip',
      version: get(config, 'common.commpressionLib'),
    },
  };
};
