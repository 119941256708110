import * as actions from './actions';

const initialFlashState = {
  visible: false,
  status: 'info',
  message: '',
  dismissable: true,
  timeout: 3000,
};

const flashReducer = (state, action) => {
  switch (action.type) {
    case actions.SHOW_FLASH:
      return {
        ...state,
        ...action.payload,
        visible: true,
      };

    case actions.HIDE_FLASH:
      return initialFlashState;

    case actions.LOCATION_CHANGE:
      return {
        ...state,
        visible: false,
      };

    default:
      return state;
  }
};

export { initialFlashState, flashReducer };
