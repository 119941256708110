import { ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { v4 as uuid } from 'uuid';
import { getToken } from '@hummingbirdtechgroup/wings-auth';

const authHandlerLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));
  return forward(operation);
});

const setRequestIdLink = setContext((_, prevContext) => ({
  ...prevContext,
  headers: {
    ...prevContext?.headers,
    // a unique request ID for debugging: this is logged on the server for each request
    'X-Request-ID': uuid(),
  },
}));

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}/v1/graphql?operation_name=${operationName}`, options);
};

const httpLink = apiUrl =>
  createHttpLink({
    uri: `${apiUrl}`,
    fetch: customFetch,
  });

const apolloConfig = apiUrl => ({
  link: ApolloLink.from([authHandlerLink, setRequestIdLink, httpLink(apiUrl)]),
  cache: new InMemoryCache(),
  queryDeduplication: true,
  name: 'webuploader',
  version: process.env.APP_VERSION,
});

export default apolloConfig;
