import { red, grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00a5cf',
      grad: 'linear-gradient(-90deg, #00a5cf, #00ceb3)',
    },
    secondary: {
      main: '#00ceb3',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f2fcfd',
    },
  },
  typography: {
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 26,
    },
    h3: {
      fontSize: 20,
      fontWeight: 300,
    },
    h4: {
      fontSize: 18,
      fontWeight: 300,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        borderRadius: '25px',
      },
      containedPrimary: {
        color: '#fff',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        backgroundColor: '#fff',
        border: '1px solid #00ceb3',
        color: '#00ceb3',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#00ceb3',
          boxShadow: 'none',
        },
        '&$disabled': {
          borderColor: grey['300'],
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
          minHeight: 56,
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
        flexDirection: 'column',
      },
    },
  },
});

export default theme;
