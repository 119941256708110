import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfiniteLoading from 'react-simple-infinite-loading';
import { MAX_NON_IMAGE_FILES_SIZE } from '../../../../utils/constants';
import formatByteText from '../../../../utils/formatByteText';
import pluralise from '../../../../utils/pluralise';
import * as errorTypes from '../../services/fileProcessing/errorTypes';
import { Accordion, Typography } from '../../../../ui-components';

const { Panel, Summary, Details } = Accordion;

const useStyles = makeStyles(theme => ({
  summarySingleLine: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.grey[100],
    border: [[1, 'solid', theme.palette.grey[300]]],
    marginBottom: -1,
  },

  summary: {
    '& span': {
      textTransform: 'capitalize',
    },
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',

    '& li': {
      lineHeight: '30px',
      padding: theme.spacing(1, 2),
      whiteSpace: 'nowrap',
    },
  },
}));

function Notification({ errorType, errorKey, count, files }) {
  const classes = useStyles();

  if (errorType === errorTypes.NO_IMAGE_FILES) {
    return (
      <div
        className={classes.summarySingleLine}
        data-testid={`notification-${errorType}`}
      >
        <ErrorOutlineIcon color="error" className={classes.icon} />
        <Typography>There are no image files selected</Typography>
      </div>
    );
  }

  return (
    <Panel data-testid={`notification-${errorType}`}>
      <Summary>
        <ErrorOutlineIcon color="error" className={classes.icon} />
        {errorType === errorTypes.META_MISSING && (
          <Typography
            className={classes.summary}
            testid={`summary-${errorKey}`}
          >
            <span>{errorKey}</span>
            {` missing from ${pluralise(count, 'file')}`}
          </Typography>
        )}

        {errorType === errorTypes.PARSE_FAIL && (
          <Typography testid={`summary-${errorKey}`}>
            {`Failed to parse the following ${pluralise(count, 'file')}`}
          </Typography>
        )}

        {errorType === errorTypes.NON_IMAGE_FILE_SIZE && (
          <Typography testid={`summary-${errorKey}`}>
            Over {formatByteText(MAX_NON_IMAGE_FILES_SIZE)} of non-image files.
          </Typography>
        )}
      </Summary>

      <Details>
        <ul style={{ width: '100%', height: 300 }} className={classes.list}>
          <InfiniteLoading items={files} itemHeight={30}>
            {files.map(file => (
              <li key={file.key} data-testid="notification-file">
                <Typography variant="caption">...{file.path}</Typography>
              </li>
            ))}
          </InfiniteLoading>
        </ul>
      </Details>
    </Panel>
  );
}

Notification.propTypes = {
  errorType: PropTypes.string.isRequired,
  errorKey: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      path: PropTypes.string,
      errorKey: PropTypes.string,
    }),
  ).isRequired,
};

export default Notification;
