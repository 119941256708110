import { LOCALE_KEY } from './constants';

export const getLocaleData = () => {
  const local = JSON.parse(localStorage.getItem(LOCALE_KEY));

  if (!local) {
    return null;
  }

  return local;
};

export const getLS = (key, fallback) => {
  return localStorage.getItem(key) || fallback;
};

/**
 * Get an object stored in local storage
 * @param {string} key Local storage item key
 * @returns null | parsed object
 */
export const getLocalStorageObject = key => {
  const result = localStorage.getItem(key);
  if (result) return JSON.parse(result);

  return null;
};

/**
 * Stringifies an object and stores it in local storage
 * @param {string} key local storage item key
 * @param {any} object Items to be stringified and stored
 */
export const setLocalStorageObject = (key, object) => {
  const stringifiedObject = JSON.stringify(object);

  localStorage.setItem(key, stringifiedObject);
};
