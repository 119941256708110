/* eslint-disable no-plusplus */

const camerTypes = {
  RGB: 'RGB',
  Multi: 'Multi',
};

const SENTERA_ARRAY = ['Red', 'Garbage', 'NIR'];

const getCameraType = file => {
  if (file['content-type'] === 'image/tiff') return camerTypes.Multi;
  const { cameraBandNames } = file.metadata;
  if (
    Array.isArray(cameraBandNames) &&
    cameraBandNames.join('').toLowerCase() ===
      SENTERA_ARRAY.join('').toLowerCase()
  ) {
    return camerTypes.Multi;
  }
  return camerTypes.RGB;
};

const getInitialImagesData = ({
  cameraType,
  count,
  altitude,
  date,
  sensor,
  flightPath,
}) => ({
  image_type: cameraType,
  total: count,
  percentage_covered: -1,
  median_altitude: altitude,
  median_date: new Date(date).toISOString(),
  flight_path: flightPath,
  sensor,
});

const arrayAvgRounded = numbers => {
  const sum = numbers.reduce((a, b) => a + b, 0);
  const avg = sum / numbers.length || 0;
  return Math.round(avg);
};

const getPreprocessedManifestImages = (imagesManifest, points) => {
  const manifestFiles =
    imagesManifest?.files && Object.values(imagesManifest?.files);
  if (!manifestFiles?.length) return [];
  const flightPath = {
    type: 'MultiPoint',
    coordinates: points,
  };
  const cameras = imagesManifest?.cameras;

  let RGBCount = 0;
  let MultiCount = 0;
  const RGBDates = [];
  const RGBAltitudes = [];
  const MultiDates = [];
  const MultiAltitudes = [];

  const RGBSensor = {
    type: camerTypes.RGB,
    model: '',
    make: '',
  };
  const MultiSensor = {
    type: camerTypes.RGB,
    model: '',
    make: '',
  };

  for (let i = 0; i < manifestFiles.length; i++) {
    const file = manifestFiles[i];
    if (!file.metadata) break;
    const cameraType = getCameraType(file);
    const datetime = file.metadata?.datetime;
    const altitude = file.metadata?.gps?.altitude;
    const camera = cameras?.[file.metadata?.camera];

    if (cameraType === camerTypes.RGB) {
      RGBCount++;
      if (datetime) RGBDates.push(new Date(datetime).getTime());
      if (altitude) RGBAltitudes.push(altitude);
      if (!RGBSensor.model && camera) {
        RGBSensor.model = camera.model;
        RGBSensor.make = camera.make;
      }
    } else {
      MultiCount++;
      if (datetime) MultiDates.push(new Date(datetime).getTime());
      if (altitude) MultiAltitudes.push(altitude);
      if (!MultiSensor.model && camera) {
        MultiSensor.model = camera.model;
        MultiSensor.make = camera.make;
      }
    }
  }

  const result = [
    RGBCount &&
      getInitialImagesData({
        cameraType: camerTypes.RGB,
        count: RGBCount,
        altitude: arrayAvgRounded(RGBAltitudes),
        date: new Date(arrayAvgRounded(RGBDates)).toISOString(),
        sensor: RGBSensor,
        flightPath,
      }),
    MultiCount &&
      getInitialImagesData({
        cameraType: camerTypes.Multi,
        count: MultiCount,
        altitude: arrayAvgRounded(MultiAltitudes),
        date: new Date(arrayAvgRounded(MultiDates)).toISOString(),
        sensor: MultiSensor,
        flightPath,
      }),
  ].filter(Boolean);

  return result;
};

export const putEtityIdsToPreprocessedImages = (
  preprocessedImages,
  flight_id,
  survey_id,
) =>
  preprocessedImages.map(preprocessedImagesItem => ({
    ...preprocessedImagesItem,
    flight_id,
    survey_id,
  }));

export default getPreprocessedManifestImages;
